import { useRef, useEffect, ReactChildren, ReactNode } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { api2 } from "./fetcher";
import { LoadNotification } from "./commonUtils";
import { AxiosError } from "axios";
import { baseURL } from "../routes/routeConfig";
import { useQueryClient } from "react-query";

export default function AxiosNavigation({ children }: { children: ReactNode }) {
  const navigate = useRef(useNavigate());
  const queryClient = useQueryClient();
  const location = useLocation();
  useEffect(() => {
    const intercetpor = api2.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response?.status === 401) {
          // 서버에서 받은 에러 상태 코드가 401(Unauthorized)인 경우
          const axiosError = error as AxiosError<{ [key: string]: any }>;
          if (axiosError.response?.data?.error === "Unauthorized") {
            // 특정 에러 메시지를 감지하여 로그인 페이지로 이동

            LoadNotification("[SYSTEM]", "세션이 유효하지 않습니다.", 0);
            navigate.current(`${baseURL}/login`);
            queryClient.setQueryData("getUserInfoFetcher", null);
            localStorage.removeItem("SOWJDQH");
            api2.interceptors.response.eject(intercetpor);
          }
        }
      }
    );

    return () => {
      api2.interceptors.response.eject(intercetpor);
    };
  }, [location]);
  return <>{children}</>;
}
