import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import colorConfigs from "../../configs/colorConfigs";
import _sidebar from "../common/Sidebar/_sidebar";
import _topbar from "../common/Header/_topbar";
import { useRecoilValue } from "recoil";
import AppFooter from "../common/Footer/_footer";
import { Wrapper } from "../common/commonComponents";
import { isToggleAtomState } from "../../state/systemInit";

import sizeConfigs from "../../configs/sizeConfigs";
import useWidth from "../../hooks/useWidth";
import { useQuery, useQueryClient } from "react-query";
import { useCallback, useEffect } from "react";
import { getUserInfoFetcher } from "../../utils/fetcher";
import { baseURL } from "../../routes/routeConfig";
import { LoadNotification } from "../../utils/commonUtils";
import useTitle from "../../hooks/useTitle";
import useMyinfo from "../../hooks/useMyinfo";
declare global {
  interface Window {
    KCP_Pay_Execute_Web: any;
    closeEvent_SPAY: any;
  }
}

const MainLayout = () => {
  const queryClient = useQueryClient();

  const isToggle = useRecoilValue(isToggleAtomState);
  const width = useWidth();
  const setTitle = useTitle(
    baseURL === "/mall/anna"
      ? "ANNA"
      : baseURL === "/mall/core"
      ? "CORE"
      : "MALL"
  );
  const movePage = useNavigate();
  const { value: myinfo } = useMyinfo();

  // const myinfo = localStorage.getItem("SOWJDQH");
  const { data: userData } = useQuery(
    ["getUserInfoFetcher"],
    () => userinfoFetcher(),
    {
      onSuccess: (e) => {
        const rtnData = e?.rtn_list?.[0];
        if (rtnData) {
          const myinfo = {
            rtn_clnt: rtnData.clnt,
            rtn_ocust: rtnData.cust,
            rtn_ocustnm: rtnData.custnm,
            rtn_status: rtnData.status,
            rtn_userid: rtnData.userid,
            rtn_offtel: rtnData.offtel,
          };

          localStorage.setItem("SOWJDQH", JSON.stringify(myinfo));
        }
      },
    }
  );
  const location = useLocation();

  const userinfoFetcher = useCallback(() => {
    const _myinfo = myinfo && JSON.parse(myinfo);
    const qs = `?as_clnt=${_myinfo?.rtn_clnt}&as_cust=${_myinfo?.rtn_ocust}`;
    return getUserInfoFetcher(qs);
  }, [myinfo]);

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: ["getUserInfoFetcher"] });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (userData) {
      if (!userData.rtn_list || userData.rtn_list.length === 0) {
        location.pathname !== baseURL && movePage(baseURL);
      } else {
        if (baseURL === "/mall/anna") {
          setTitle("ANNA");
        } else if (baseURL === "/mall/core") {
          setTitle("CORE");
        }

        if (userData.rtn_list[0].status === "NEW") {
          location.pathname !== `${baseURL}/myinfo` &&
            movePage(`${baseURL}/myinfo`);
          location.pathname === `${baseURL}/myinfo` &&
            LoadNotification(
              "[SYSTEM]",
              "신규 유저는 비밀번호 변경 후 서비스 이용이 가능합니다.",
              0
            );
        }
      }
    }
  }, [userData, location]);

  return (
    <Wrapper
      width={`100%`}
      ju={`flex-start`}
      height={width < 950 ? `100dvh` : `auto`}
      overflowY={width < 950 ? `scroll` : ``}
      overflowX={width < 950 ? `scroll` : ``}
      padding={width < 950 ? `64px 0` : `64px 0 0`}
    >
      <_topbar />

      {width < 950 ? (
        <Wrapper padding={`10px`}>
          <Outlet />
        </Wrapper>
      ) : (
        <Wrapper
          dr={`row`}
          height={`calc(100dvh - 64px)`}
          minHeight={`calc(100dvh - 64px)`}
          maxHeight={`calc(100dvh - 64px)`}
          al={`stretch`}
          ju={`flex-end`}
        >
          <_sidebar />
          <Wrapper
            width={
              isToggle?.toggle === true
                ? `calc(100% - ${sizeConfigs.sidebarEnable.width})`
                : `calc(100% - ${sizeConfigs.sidebarDisable.width})`
            }
            overflowY="scroll"
            position="relative"
            al={`flex-start`}
            ju={`space-between`}
            overflowX={`hidden`}
            bgColor={colorConfigs.mainBg}
          >
            <Wrapper al={`flex-start`} padding={`0 30px`} position={`relative`}>
              <Wrapper
                minHeight={`calc(100dvh - 64px)`}
                padding={`30px 0`}
                ju={`flex-start`}
              >
                <Outlet />
              </Wrapper>
              <AppFooter />
            </Wrapper>
          </Wrapper>
        </Wrapper>
      )}
      {width < 950 && <AppFooter />}
    </Wrapper>
  );
};

export default MainLayout;
