import { atom, useRecoilValue } from "recoil";

export type SystemInit = {
  loginActivate: boolean;
  mainContractorModule: SystemMainContractorModule;
  subContractorModule: SystemSubContractorModule;
};

export type SystemMainContractorModule = {
  uid: string;
};

export type SystemSubContractorModule = {
  uid: string;
};

export const systemInitState = atom<SystemInit | null>({
  key: "systemInit",
  default: null,
});

export type IsToggleAtom = {
  toggle: boolean;
};

export type IsLanguage = {
  language: string;
};

export const isToggleAtomState = atom<IsToggleAtom | null>({
  key: "isToggle",
  default: {
    toggle: true,
  },
});

export const isLanguageState = atom<IsLanguage | null>({
  key: "isLanguage",
  default: null,
});

export function useLanguage() {
  return useRecoilValue(isLanguageState);
}
