import { Button, Form, Modal, Radio, Table, Tabs, Tag } from "antd";
import { Text, Wrapper } from "../../../components/common/commonComponents";
import colorConfigs from "../../../configs/colorConfigs";
import { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import DaumPostCode from "react-daum-postcode";
import {
  paygetcartinfoFetcher,
  preprocess,
  preprocessFetcher,
} from "../../../utils/fetcher";
import { useMutation, useQuery } from "react-query";
import { as_clnt } from "../../../configs/clientConfig";

import useWidth from "../../../hooks/useWidth";
import { useLocation, useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";
import { LoadNotification, numberWithCommas } from "../../../utils/commonUtils";
import useInput from "../../../hooks/useInput";
import { baseURL } from "../../../routes/routeConfig";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  HomeOutlined,
  UserOutlined,
} from "@ant-design/icons";

const PaymentResult = () => {
  const movePage = useNavigate();

  const location = useLocation();
  const res_cd = new URLSearchParams(location.search).get("res_cd");
  const res_msg = new URLSearchParams(location.search).get("res_msg");
  useEffect(() => {
    const res_cd = new URLSearchParams(location.search).get("res_cd");
    const res_msg = new URLSearchParams(location.search).get("res_msg");

    if (!res_cd || !res_msg || res_cd === "" || res_msg === "") {
      LoadNotification("[SYSTEM]", "잘못된 접근입니다.", 0);
      movePage(`${baseURL}/product/list`);
    }
  }, [location]);

  return (
    <Wrapper al={`flex-start`} ju={`flex-start`} height={`100%`}>
      <Wrapper
        width={`100%`}
        margin={`0 0 50px`}
        radius={`10px`}
        shadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
        overflow="hidden"
        bgColor={colorConfigs.basic.white}
        padding={`20px`}
        height={`80dvh`}
      >
        <Text
          fontSize="20px"
          color={colorConfigs.themeColor.theme1}
          margin={`0 0 20px`}
        >
          <Wrapper fontSize="60px" margin={`0 0 30px`}>
            {res_cd === "0000" ? (
              <CheckCircleOutlined
                style={{ color: colorConfigs.table_status.existence }}
              />
            ) : (
              <ExclamationCircleOutlined
                style={{ color: colorConfigs.table_status.receiving }}
              />
            )}
          </Wrapper>
          {res_cd === "0000" ? "결제가 완료되었습니다." : res_msg}
        </Text>
        <Wrapper dr={`row`}>
          <Button
            icon={<HomeOutlined />}
            size="large"
            type="primary"
            ghost
            onClick={() => movePage(`${baseURL}/product/list`)}
          >
            메인페이지
          </Button>
          &nbsp; &nbsp;
          <Button
            icon={<UserOutlined />}
            size="large"
            type="primary"
            ghost
            onClick={() => movePage(`${baseURL}/mypage`)}
          >
            마이페이지
          </Button>
        </Wrapper>
      </Wrapper>
    </Wrapper>
  );
};

export default PaymentResult;
