import {
  Breadcrumb,
  Button,
  DatePicker,
  Descriptions,
  Divider,
  Empty,
  Form,
  Image,
  Input,
  Modal,
  Popconfirm,
  Table,
  Tabs,
  TabsProps,
  Tag,
} from "antd";
import { Text, Wrapper } from "../../../components/common/commonComponents";
import {
  CalendarOutlined,
  EditOutlined,
  FileSearchOutlined,
  SearchOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import colorConfigs from "../../../configs/colorConfigs";
import { ColumnGroupType } from "antd/lib/table";
import { ColumnsType } from "antd/es/table";
import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { AlignType, FixedType } from "rc-table/lib/interface";
import { LoadNotification, numberWithCommas } from "../../../utils/commonUtils";
import { getMyPageFetcher, orderCancelFetcher } from "../../../utils/fetcher";
import { useMutation, useQuery } from "react-query";
import dayjs from "dayjs";
import moment from "moment";
import { as_clnt, isTest } from "../../../configs/clientConfig";
import useWidth from "../../../hooks/useWidth";

interface Product {
  id: string;
}
const format = "YYYY-MM-DD";
const CustomTag = styled(Tag)`
  margin: 0;
  padding: 10px 5px;
  font-size: 14px;
`;

const CustomTable = styled(Table)`
  & .ant-table-cell {
    /* background: red; */
  }
`;

const CustomTabs = styled(Tabs)`
  width: 100%;
`;

const MyPage = () => {
  const myinfo = localStorage.getItem("SOWJDQH");
  const [tab, setTab] = useState<string>("CURRENT");
  const [textArea, setTextArea] = useState<string>("");
  const [modal, setModal] = useState<null | mypageData>(null);
  const [viewModal, setViewModal] = useState<null | mypageData>(null);
  const width = useWidth();
  const [rangePicker, setRangePicker] = useState<[string, string]>([
    dayjs().format("YYYY-MM-DD"),
    dayjs().format("YYYY-MM-DD"),
  ]);
  const {
    data: productData,
    refetch,
    isLoading,
    isFetching,
  } = useQuery(
    ["getMyPageFetcher"],
    () => {
      return fetcher();
    },
    {
      staleTime: Infinity,
      refetchInterval: false,
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        data?.rtn_code === 0 &&
          LoadNotification("[SYSTEM]", data?.rtn_msg, 0, 2);
      },
    }
  );

  const { mutate: cancelMutate, isLoading: cancelLoading } = useMutation(
    orderCancelFetcher,
    {
      onSuccess: () => {
        refetch();
        LoadNotification("[SYSTEM]", "주문이 취소되었습니다.", 1, 2);
        setModal(null);
        setTextArea("");
      },
    }
  );
  interface mypageData {
    addr: string;
    addr_dtl: string;
    custnm: string;
    dlv_statusnm: string;
    dlvrmk: string;
    dlvfee: number;
    email: string;
    order_count: number;
    pay_cancel_able: string;
    pay_method: string;
    pay_methodnm: string;
    pay_pg_sno: string;
    pay_res_cd: string;
    pay_res_msg: string;
    pay_time: string;
    pay_amt: number;
    preamt: number;
    recdt: string;
    receiver: string;
    recno: string;
    rectelno: string;
    site_cd: string;
    total_ordamt: number;
    rtn_sublist: mypageDataSub[];
  }
  interface mypageDataSub {
    colornm?: string;
    itm?: string;
    itmcd?: string;
    itmcdnm?: string;
    itmid?: number;
    makernm?: string;
    ordamt?: number;
    ordprc?: number;
    ordqty?: number;
    originnm?: string;
    recid?: number;
    sizenm?: string;
    stno: string;
  }
  const fetcher = useCallback(() => {
    let _qs = "";
    const _myinfo = myinfo && JSON.parse(myinfo);
    const as_cust = _myinfo?.rtn_ocust;
    _qs = `?as_clnt=${as_clnt}&as_cust=${as_cust}&as_action=${tab}&as_frdt=${rangePicker[0]}&as_todt=${rangePicker[1]}`;
    const data = {
      as_clnt: as_clnt,
      as_cust: as_cust,
      as_action: tab,
      as_frdt: rangePicker[0],
      as_todt: rangePicker[1],
    };
    console.log(data);
    return getMyPageFetcher(data);
  }, [tab, as_clnt, myinfo, rangePicker]);

  const dataPickerBtn = useCallback((type: string) => {
    let fromdt = "";
    let todt = "";
    const today = dayjs();

    switch (type) {
      case "prevMonth":
        fromdt = today
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD");
        todt = today.subtract(1, "month").endOf("month").format("YYYY-MM-DD");
        break;
      case "prev6Month":
        fromdt = today
          .subtract(6, "month")
          .startOf("month")
          .format("YYYY-MM-DD");
        todt = today.subtract(1, "month").endOf("month").format("YYYY-MM-DD");
        break;
      case "year":
        fromdt = today.startOf("year").format("YYYY-MM-DD");
        todt = today.endOf("year").format("YYYY-MM-DD");
        break;
      default:
        break;
    }

    return setRangePicker([fromdt, todt]);
  }, []);

  const searchHandldr = useCallback(() => {
    refetch();
  }, []);

  useEffect(() => {
    refetch();
  }, [tab]);

  const cancelMutateHandler = useCallback(() => {
    const _myinfo = myinfo && JSON.parse(myinfo);

    if (_myinfo && modal) {
      const as_cust = _myinfo?.rtn_ocust;
      const calcelData = {
        as_clnt,
        as_cust,
        site_cd: isTest ? "T0000" : modal.site_cd,
        tno: modal.pay_pg_sno,
        mod_type: "STSC",
        mod_mny: modal.pay_amt,
        rem_mny: 0,
        mod_desc: textArea || "",
        ordr_idxx: modal.recno,
      };
      cancelMutate(calcelData);
    }
  }, [modal, textArea, myinfo, as_clnt, isTest]);

  const prev_columns = [
    {
      title: "주문일자",
      align: "center" as AlignType,
      render: (data: mypageData) => {
        return <Text>{moment(data?.recdt).format(`YYYY-MM-DD`)}</Text>;
      },
    },

    {
      title: "주문번호",
      align: "center" as AlignType,
      render: (data: mypageData) => {
        return (
          <Text
            color={colorConfigs.table_status.forwarding}
            cursor="pointer"
            onClick={() => setViewModal(data)}
          >
            <FileSearchOutlined />
            &nbsp;{data?.recno}
          </Text>
        );
      },
    },

    {
      title: "수취인 이름",
      align: "center" as AlignType,
      render: (data: mypageData) => {
        return <Text>{data?.receiver}</Text>;
      },
    },

    {
      title: "수취인 연락처",
      align: "center" as AlignType,
      render: (data: mypageData) => {
        return <Text>{data?.rectelno}</Text>;
      },
    },

    {
      title: "상품코드",
      align: "center" as AlignType,
      render: (data: mypageData) => {
        return <Text>{data?.rtn_sublist?.[0].itmcd}</Text>;
      },
    },

    {
      title: "항목",
      align: "center" as AlignType,
      render: (data: mypageData) => {
        return (
          <Text>
            {data?.rtn_sublist?.[0].itmcdnm}&nbsp;
            {data?.rtn_sublist?.length <= 1
              ? ``
              : `외 ${data?.rtn_sublist?.length - 1}건`}
          </Text>
        );
      },
    },

    // {
    //   title: "색상",
    //   align: "center" as AlignType,
    //   render: (data: mypageData) => {
    //     return <Text>{data?.rtn_sublist?.[0].colornm}</Text>;
    //   },
    // },
    // {
    //   title: "사이즈",
    //   align: "center" as AlignType,
    //   render: (data: mypageData) => {
    //     return <Text>{data?.rtn_sublist?.[0].sizenm}</Text>;
    //   },
    // },
    // {
    //   title: "수량",
    //   align: "center" as AlignType,
    //   render: (data: mypageData) => {
    //     return <Text>{data?.rtn_sublist?.[0].ordqty}</Text>;
    //   },
    // },
    // {
    //   title: "상품금액",
    //   align: "center" as AlignType,
    //   render: (data: mypageData) => {
    //     return (
    //       <Text>
    //         {data?.rtn_sublist?.[0].ordamt &&
    //           numberWithCommas(data?.rtn_sublist?.[0].ordamt)}
    //         원
    //       </Text>
    //     );
    //   },
    // },
    // {
    //   title: "배송비",
    //   align: "center" as AlignType,
    //   render: (data: mypageData) => {
    //     return (
    //       <Text>
    //         {data?.dlvfee && numberWithCommas(data?.dlvfee)}원
    //       </Text>
    //     );
    //   },
    // },
    // {
    //   title: "선수금",
    //   align: "center" as AlignType,
    //   render: (data: mypageData) => {
    //     return (
    //       <Text>
    //         {data?.preamt && numberWithCommas(data?.preamt)}원
    //       </Text>
    //     );
    //   },
    // },
    {
      title: "결제금액",
      align: "center" as AlignType,
      render: (data: mypageData) => {
        return (
          <Text>{data?.pay_amt && numberWithCommas(data?.pay_amt)}원</Text>
        );
      },
    },
    // {
    //   title: "배송지",
    //   align: "center" as AlignType,
    //   render: (data: mypageData) => {
    //     return <>{`Data 누락`}</>;
    //   },
    // },
    // {
    //   title: "접수자",
    //   align: "center" as AlignType,
    //   render: (data: mypageData) => {
    //     return <>{`Data 누락`}</>;
    //   },
    // },
  ];

  const detailProductColumns = [
    {
      title: "상품 코드",
      align: "center" as AlignType,
      render: (data: mypageDataSub) => {
        return data?.itmcd;
      },
    },
    {
      title: "상품명",
      align: "center" as AlignType,
      render: (data: mypageDataSub) => {
        return data?.itmcdnm;
      },
    },
    {
      title: "색상",
      align: "center" as AlignType,
      render: (data: mypageDataSub) => {
        return data?.colornm;
      },
    },
    {
      title: "사이즈",
      align: "center" as AlignType,
      render: (data: mypageDataSub) => {
        return data?.sizenm;
      },
    },
    {
      title: "상품 금액",
      align: "center" as AlignType,
      render: (data: mypageDataSub) => {
        return data?.ordprc && numberWithCommas(data?.ordprc) + "원";
      },
    },
    {
      title: "수량",
      align: "center" as AlignType,
      render: (data: mypageDataSub) => {
        return data?.ordqty && numberWithCommas(data?.ordqty);
      },
    },
    {
      title: "총금액",
      align: "center" as AlignType,
      render: (data: mypageDataSub) => {
        return data?.ordamt && numberWithCommas(data?.ordamt) + "원";
      },
    },
    {
      title: "송장번호",
      align: "center" as AlignType,
      render: (data: mypageDataSub) => {
        return data?.stno || "";
      },
    },
  ];
  const current_columns = [
    {
      title: "처리상태",
      width: `100px`,
      align: "center" as AlignType,
      render: (data: mypageData) => {
        return <Text>{data?.dlv_statusnm}</Text>;
      },
    },
    ...prev_columns,
    {
      title: "취소",
      width: `120px`,
      fixed: "right" as FixedType,
      align: "center" as AlignType,
      render: (data: mypageData) => {
        if (data.pay_cancel_able !== "Y") {
          return <Tag color={`red`}>취소 불가능</Tag>;
        }
        return (
          <Popconfirm
            title="[SYSTEM]"
            placement="topLeft"
            description={`주문을 취소하시겠습니까?`}
            okButtonProps={{ size: `middle` }}
            cancelButtonProps={{ size: "middle" }}
            onConfirm={() => setModal(data)}
          >
            <Button type="primary" ghost>
              취소하기
            </Button>
          </Popconfirm>
        );
      },
    },
  ];

  const items: TabsProps["items"] = [
    {
      key: "CURRENT",
      label: "진행중인 목록",
    },
    {
      key: "PRIOR",
      label: "이전구매 목록",
    },
  ];
  return (
    <Wrapper al={`flex-start`} ju={`flex-start`} height={`100%`}>
      <Wrapper
        dr={`row`}
        ju={`space-between`}
        bgColor={colorConfigs.basic.white}
        shadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
        padding={width < 950 ? `15px` : `30px`}
        radius={`10px`}
        margin={`0 0 50px`}
      >
        <Breadcrumb
          style={{ margin: `0 0 10px` }}
          items={[
            {
              title: (
                <>
                  <ShoppingCartOutlined style={{ margin: `0 10px 0 0` }} />
                  마이페이지
                </>
              ),
            },
          ]}
        />
      </Wrapper>
      <Wrapper
        margin={`0 0 50px`}
        radius={`10px`}
        shadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
        overflow="hidden"
        bgColor={colorConfigs.basic.white}
      >
        <Wrapper padding={`0 20px`}>
          <CustomTabs
            items={items}
            size={`large`}
            onChange={(e) => setTab(e)}
          />
        </Wrapper>
        {console.log(productData)}
        {tab === "CURRENT" ? (
          <Wrapper padding={`20px`}>
            <CustomTable
              size={`small`}
              style={{ width: `100%` }}
              columns={current_columns}
              dataSource={productData?.rtn_list || []}
              scroll={{ x: 1300 }}
            />
          </Wrapper>
        ) : (
          <Wrapper dr={`row`}>
            <Wrapper dr={`row`} ju={`flex-start`} padding={`20px`}>
              <Wrapper
                width={`auto`}
                dr={`row`}
                ju={`flex-start`}
                margin={width < 950 ? `0` : `0 20px 0 0`}
              >
                <Text margin={`0 20px 0 0`}>조회시간</Text>
                <DatePicker.RangePicker
                  style={{ width: width < 950 ? `100%` : `` }}
                  onChange={(_, data) => setRangePicker(data)}
                  value={[
                    dayjs(rangePicker[0], format),
                    dayjs(rangePicker[1], format),
                  ]}
                  allowClear={false}
                />
              </Wrapper>
              <Wrapper
                width={width < 950 ? `100%` : `auto`}
                dr={`row`}
                ju={width < 950 ? `space-between` : `flex-start`}
                margin={width < 950 ? `10px 0 0` : `0`}
              >
                <Button
                  type={`primary`}
                  ghost
                  icon={<CalendarOutlined />}
                  style={{ margin: width < 950 ? `0` : `0 20px 0 0` }}
                  onClick={() => dataPickerBtn("prevMonth")}
                >
                  전월
                </Button>
                <Button
                  type={`primary`}
                  ghost
                  icon={<CalendarOutlined />}
                  style={{ margin: width < 950 ? `0` : `0 20px 0 0` }}
                  onClick={() => dataPickerBtn("prev6Month")}
                >
                  이전 6개월
                </Button>
                <Button
                  type={`primary`}
                  ghost
                  icon={<CalendarOutlined />}
                  onClick={() => dataPickerBtn("year")}
                  style={{ margin: width < 950 ? `0` : `0 20px 0 0` }}
                >
                  연간
                </Button>
                <Wrapper
                  width={width < 950 ? `100%` : `auto`}
                  al={`flex-end`}
                  margin={width < 950 ? `10px 0 0` : `0`}
                >
                  <Button
                    type={`primary`}
                    icon={<SearchOutlined />}
                    onClick={() => searchHandldr()}
                  >
                    검색
                  </Button>
                </Wrapper>
              </Wrapper>
              <Wrapper margin={`20px 0 0`}>
                <Table
                  size={`small`}
                  style={{ width: `100%` }}
                  loading={isLoading}
                  columns={prev_columns}
                  dataSource={productData?.rtn_list || []}
                  scroll={{ x: 1300 }}
                />
              </Wrapper>
            </Wrapper>
          </Wrapper>
        )}
      </Wrapper>
      <Modal
        open={modal !== null}
        title={`상품 취소`}
        closable={false}
        cancelText={`닫기`}
        okText={`주문 취소`}
        onCancel={() => {
          setModal(null);
          setTextArea("");
        }}
        okButtonProps={{ loading: cancelLoading }}
        onOk={cancelMutateHandler}
        width={`800px`}
      >
        {modal && (
          <>
            <Descriptions
              bordered
              size="small"
              style={{ width: width < 950 ? `100%` : `500px` }}
            >
              <Descriptions.Item span={24} label="주문번호">
                {modal.recno}
              </Descriptions.Item>
              <Descriptions.Item span={24} label="항목">
                {modal?.rtn_sublist?.[0].itmcdnm}&nbsp;
                {modal?.rtn_sublist?.length <= 1
                  ? ``
                  : `외 ${modal?.rtn_sublist?.length - 1}건`}
              </Descriptions.Item>
              <Descriptions.Item span={24} label="수취인 이름">
                {modal?.receiver || ""}
              </Descriptions.Item>
              <Descriptions.Item span={24} label="수취인 연락처">
                {modal?.rectelno || ""}
              </Descriptions.Item>
              <Descriptions.Item span={24} label="배송지">
                {modal?.addr || ""}&nbsp;
                {modal?.addr_dtl || ""}
              </Descriptions.Item>
              <Descriptions.Item span={24} label="배송시 요청사항">
                {modal?.dlvrmk || ""}
              </Descriptions.Item>
              <Descriptions.Item span={24} label="주문금액">
                <Wrapper fontSize="14px" al={`flex-end`}>
                  {modal.total_ordamt === 0
                    ? `0`
                    : numberWithCommas(modal.total_ordamt)}
                  원
                </Wrapper>
              </Descriptions.Item>
              <Descriptions.Item span={24} label="배송비">
                <Wrapper fontSize="14px" al={`flex-end`}>
                  {modal.dlvfee === 0 ? `0` : numberWithCommas(modal.dlvfee)}원
                </Wrapper>
              </Descriptions.Item>
              <Descriptions.Item span={24} label="선수금">
                <Wrapper fontSize="14px" al={`flex-end`}>
                  {modal.preamt === 0 ? `0` : numberWithCommas(modal.preamt)}원
                </Wrapper>
              </Descriptions.Item>
              <Descriptions.Item span={24} label="결제금액">
                <Wrapper fontSize="14px" al={`flex-end`}>
                  {numberWithCommas(modal.pay_amt)}원
                </Wrapper>
              </Descriptions.Item>
            </Descriptions>
            <Text margin={`20px 0 10px 0`}>상품 상세 리스트</Text>
            <Table
              size={`small`}
              columns={detailProductColumns}
              dataSource={modal?.rtn_sublist || []}
              scroll={{ x: 752 }}
              pagination={{ pageSize: 5, showSizeChanger: false }}
            />
          </>
        )}
        <br />

        <Text margin={`0 0 10px`}>취소 사유</Text>

        <Input.TextArea
          value={textArea}
          onChange={(e) => setTextArea(e.target.value)}
          maxLength={200}
        />
      </Modal>

      <Modal
        title={`주문내역 상세보기`}
        open={viewModal !== null}
        onCancel={() => setViewModal(null)}
        footer={null}
        width={`800px`}
      >
        {viewModal && (
          <>
            <Descriptions
              bordered
              size="small"
              style={{ width: width < 950 ? `100%` : `500px` }}
            >
              <Descriptions.Item span={24} label="주문번호">
                {viewModal.recno}
              </Descriptions.Item>
              <Descriptions.Item span={24} label="항목">
                {viewModal?.rtn_sublist?.[0].itmcdnm}&nbsp;
                {viewModal?.rtn_sublist?.length <= 1
                  ? ``
                  : `외 ${viewModal?.rtn_sublist?.length - 1}건`}
              </Descriptions.Item>
              <Descriptions.Item span={24} label="수취인 이름">
                {viewModal?.receiver || ""}
              </Descriptions.Item>
              <Descriptions.Item span={24} label="수취인 연락처">
                {viewModal?.rectelno || ""}
              </Descriptions.Item>
              <Descriptions.Item span={24} label="배송지">
                {viewModal.addr || ""}&nbsp;{viewModal.addr_dtl || ""}
              </Descriptions.Item>
              <Descriptions.Item span={24} label="배송시 요청사항">
                {viewModal?.dlvrmk
                  ? viewModal?.dlvrmk?.split?.("\n").map((dt: string) => {
                      return (
                        <>
                          {dt}
                          <br />
                        </>
                      );
                    })
                  : ""}
              </Descriptions.Item>
              <Descriptions.Item span={24} label="주문금액">
                <Wrapper fontSize="14px" al={`flex-end`}>
                  {viewModal.total_ordamt === 0
                    ? `0`
                    : numberWithCommas(viewModal.total_ordamt)}
                  원
                </Wrapper>
              </Descriptions.Item>
              <Descriptions.Item span={24} label="배송비">
                <Wrapper fontSize="14px" al={`flex-end`}>
                  {viewModal.dlvfee === 0
                    ? `0`
                    : numberWithCommas(viewModal.dlvfee)}
                  원
                </Wrapper>
              </Descriptions.Item>
              <Descriptions.Item span={24} label="선수금">
                <Wrapper fontSize="14px" al={`flex-end`}>
                  {viewModal.preamt === 0
                    ? `0`
                    : numberWithCommas(viewModal.preamt)}
                  원
                </Wrapper>
              </Descriptions.Item>
              <Descriptions.Item span={24} label="결제금액">
                <Wrapper fontSize="14px" al={`flex-end`}>
                  {numberWithCommas(viewModal.pay_amt)}원
                </Wrapper>
              </Descriptions.Item>
            </Descriptions>
            <Text margin={`20px 0 10px 0`}>상품 상세 리스트</Text>
            <Table
              size={`small`}
              columns={detailProductColumns}
              dataSource={viewModal?.rtn_sublist || []}
              scroll={{ x: 752 }}
              pagination={{ pageSize: 5, showSizeChanger: false }}
            />
          </>
        )}
      </Modal>
    </Wrapper>
  );
};

export default MyPage;
