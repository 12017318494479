interface ColorObj {
  [key: string]: ColorObj | string;
}

const colorConfigs = {
  basic: {
    white: "#fff",
    grey1: "#aaa",
    grey2: "#777",
    black: "#000",
    bwhite: "#d2cfcf",
    bwhite2: "#f3f3f3",
    lightGray: "#f5f5f5",
  },
  themeColor: {
    theme1: `#3700B3`,
    theme2: `#6200EE`,
  },
  pointColor: {
    green: "3f8600",
  },
  sidebar: {
    bg: "#233044",
    color: "#eeeeee",
    hoverBg: "#1e2a3a",
    activeBg: "#1e253a",
  },
  table_status: {
    // empty: #fff  //  basic.white 사용
    existence: "#4CAF50",
    ban: "#F44336",
    receiving: "#FF9800",
    forwarding: "#2196F3",
    booking: "#FFEB3B",
    publicShipment: "#9C27B0",
    doubleShipment: "#E91E63",
    InventoryCheck: "#BC8F8F",
    crane: "#ffcc80",
    hover: "#feb429",
  },
  mainBg: "#F5F5F5",
};

export default colorConfigs;
