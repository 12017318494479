import {
  Breadcrumb,
  Button,
  Divider,
  Form,
  Image,
  Input,
  Modal,
  Popconfirm,
  Table,
  Tag,
  message,
} from "antd";
import { Text, Wrapper } from "../../../components/common/commonComponents";
import { ShoppingCartOutlined, UserOutlined } from "@ant-design/icons";
import colorConfigs from "../../../configs/colorConfigs";
import { ColumnGroupType } from "antd/lib/table";
import { ColumnsType } from "antd/es/table";
import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { AlignType } from "rc-table/lib/interface";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  getUserInfoFetcher,
  updateLoginInfo,
  updateLoginInfoFetcher,
  updateUserInfo,
  updateUserInfoFetcher,
} from "../../../utils/fetcher";
import DaumPostCode from "react-daum-postcode";
import { RuleObject } from "antd/es/form";
import { LoadNotification } from "../../../utils/commonUtils";
import useWidth from "../../../hooks/useWidth";
import useOnlyNumberInput from "../../../hooks/useOnlyNumberInput";
interface manager {
  ccid?: number;
  ccnm?: string;
  deptnm?: string;
  email?: string;
  mobile?: string;
  stpos?: string;
  useyn?: string;
}
const FormItem = styled(Form.Item)`
  & .ant-form-item-label {
    padding: 0;
  }
  width: 100%;
  margin: 0 0 10px;
`;
const MyInfo = () => {
  // hooks
  const [updateData, setUpdateData] = useState<manager | null>(null);
  const [adrsModal, setAdrsModal] = useState<boolean>(false);
  const [managerUpdateForm] = Form.useForm();
  const [idPwForm] = Form.useForm();
  const [userinfoForm] = Form.useForm();
  const myinfo = localStorage.getItem("SOWJDQH");
  const width = useWidth();
  const new_passwd = Form.useWatch("new_passwd", idPwForm);
  const passwd_check = Form.useWatch("passwd_check", idPwForm);
  const queryClient = useQueryClient();

  // queryClient.invalidateQueries({ queryKey: ['todos'] })
  // use query
  const {
    data: userinfo,
    // refetch: userinfoRefetch,
    isLoading: userinfoLoading,
  } = useQuery(["getUserInfoFetcher"], () => userinfoFetcher(), {
    staleTime: Infinity,
    refetchInterval: false,
    retry: false,
    refetchOnWindowFocus: false,
    onSuccess: (e) => {
      const rtnData = e?.rtn_list?.[0];
      if (rtnData) {
        const myinfo = {
          rtn_clnt: rtnData.clnt,
          rtn_ocust: rtnData.cust,
          rtn_ocustnm: rtnData.custnm,
          rtn_status: rtnData.status,
          rtn_userid: rtnData.userid,
          rtn_offtel: rtnData.offtel,
        };

        localStorage.setItem("SOWJDQH", JSON.stringify(myinfo));
      }
    },
  });

  // use mutation
  const { mutate: userInfoMutate, isLoading: userInfoLoading } = useMutation({
    mutationFn: (data: updateLoginInfo) => {
      return updateUserInfoFetcher(data);
    },
    onSuccess: (data) => {
      managerUpdateForm.resetFields();
      setUpdateData(null);
      queryClient.invalidateQueries({ queryKey: ["getUserInfoFetcher"] });
      if (data.rtn_code !== 1) {
        return LoadNotification("[SYSTEM]", data.rtn_msg, 0);
      }
      LoadNotification("[SYSTEM]", "저장되었습니다.", 1);
    },
  });

  const { mutate: loginInfoMutate, isLoading: loginInfoLoading } = useMutation({
    mutationFn: (data: updateUserInfo) => {
      return updateLoginInfoFetcher(data);
    },
    onSuccess: (data) => {
      managerUpdateForm.resetFields();
      setUpdateData(null);
      queryClient.invalidateQueries({ queryKey: ["getUserInfoFetcher"] });
      if (data.rtn_code !== 1) {
        return LoadNotification("[SYSTEM]", data.rtn_msg, 0);
      }
      LoadNotification("[SYSTEM]", "저장되었습니다.", 1);
    },
  });

  const userinfoFetcher = useCallback(() => {
    const _myinfo = myinfo && JSON.parse(myinfo);
    const qs = `?as_clnt=${_myinfo?.rtn_clnt}&as_cust=${_myinfo?.rtn_ocust}`;
    return getUserInfoFetcher(qs);
  }, [myinfo]);

  // handler
  const userinfoUpdateHandler = useCallback(
    (e) => {
      const _myinfo = myinfo && JSON.parse(myinfo);
      const req_data = {
        as_clnt: _myinfo?.rtn_clnt,
        as_cust: _myinfo?.rtn_ocust,
        as_jsondata: JSON.stringify({
          clnt: _myinfo?.rtn_clnt,
          cust: _myinfo?.rtn_ocust,
          custnm: e.custnm,
          rptnm: e.rptnm,
          custid: e.custid,
          zip1: e.zip1,
          addr1: e.addr1,
          offtel: e.offtel,
          offfax: e.offfax,
        }),
      };

      userInfoMutate(req_data);
    },
    [myinfo]
  );
  const idPwUpdateHandler = useCallback(
    (e) => {
      // 비밀번호 조건 추가
      // 현재 비밀번호 일치여부 추가
      //       user_id
      // origin_passwd
      // new_passwd
      // passwd_check
      const _myinfo = myinfo && JSON.parse(myinfo);
      const req_data = {
        as_clnt: _myinfo?.rtn_clnt,
        as_cust: _myinfo?.rtn_ocust,
        as_jsondata: JSON.stringify({
          userid: e?.user_id,
          passwd: e?.new_passwd,
        }),
      };
      loginInfoMutate(req_data);
    },
    [myinfo]
  );

  const adrsHandler = useCallback((e) => {
    userinfoForm.setFieldValue("zip1", e.zonecode);
    userinfoForm.setFieldValue("addr1", e.address);
    setAdrsModal((prev) => !prev);
  }, []);

  const validateUserid = (rule: RuleObject, value: any) => {
    if (value && (value.length < 5 || value.length > 20)) {
      return Promise.reject("아이디는 5자에서 20자 사이여야 합니다.");
    } else {
      return Promise.resolve();
    }
  };

  const setAdrs = useCallback(() => {
    userinfoForm.setFieldsValue({
      zip1: userinfo?.rtn_list?.[0]?.zip1,
      addr1: userinfo?.rtn_list?.[0]?.addr1,
    });
  }, [userinfoForm, userinfo]);

  const validatePassword = useCallback(
    (_: RuleObject, value: any, check: string) => {
      const regex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\!\@\#\$\%\^\&\*\(\)\-\=\_\+\`\~\<\>\,\.\?\/\|\\\₩\{\}\[\]/;/:/"/'])[A-Za-z\d\!\@\#\$\%\^\&\*\(\)\-\=\_\+\`\~\<\>\,\.\?\/\|\\\₩\{\}\[\]/;/:/"/']{8,20}$/;

      const userid = idPwForm.getFieldValue("user_id");

      if (!regex.test(value)) {
        return Promise.reject(
          new Error(
            "비밀번호는 대소문자, 특수문자, 숫자를 모두 포함하여 8자 이상 20자 이내로 설정하세요."
          )
        );
      }
      if (value === userid) {
        return Promise.reject(
          new Error(" 패스워드는 사용자 아이디와 동일할수 없습니다.")
        );
      }

      if (check === "check" && new_passwd !== passwd_check) {
        return Promise.reject(
          new Error("새 비밀번호 확인이 일치하지 않습니다.")
        );
      }

      return Promise.resolve();
    },
    [new_passwd, passwd_check]
  );

  const validateNumber = (_: any, value: string) => {
    const reg = /^[0-9]*$/;
    if (reg.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject("숫자만 입력해주세요.");
  };

  // use effect

  useEffect(() => {
    if (userinfo?.rtn_list?.[0]) {
      idPwForm.setFieldsValue({
        user_id: userinfo?.rtn_list?.[0]?.userid,
        origin_passwd: userinfo?.rtn_list?.[0]?.pwd,
      });
      userinfoForm.setFieldsValue({
        custnm: userinfo?.rtn_list?.[0]?.custnm,
        rptnm: userinfo?.rtn_list?.[0]?.rptnm,
        custid: userinfo?.rtn_list?.[0]?.custid,
        zip1: userinfo?.rtn_list?.[0]?.zip1,
        addr1: userinfo?.rtn_list?.[0]?.addr1,
        offtel: userinfo?.rtn_list?.[0]?.offtel,
        offfax: userinfo?.rtn_list?.[0]?.offfax,
      });
    }
  }, [userinfo]);
  useEffect(() => {
    if (!updateData) {
      managerUpdateForm.resetFields();
    } else {
      setTimeout(() => {
        managerUpdateForm.setFieldsValue({
          ccnm: updateData.ccnm,
          deptnm: updateData.deptnm,
          mobile: updateData.mobile,
          email: updateData.email,
        });
      }, 500);
    }
  }, [updateData]);

  useEffect(() => {
    if (myinfo) {
      queryClient.invalidateQueries({ queryKey: ["getUserInfoFetcher"] });
    }
  }, [myinfo]);

  return (
    <Wrapper al={`flex-start`} ju={`flex-start`} height={`100%`}>
      <Wrapper
        dr={`row`}
        ju={`space-between`}
        bgColor={colorConfigs.basic.white}
        shadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
        padding={width < 950 ? `15px` : `30px`}
        radius={`10px`}
        margin={`0 0 50px`}
      >
        <Breadcrumb
          items={[
            {
              title: (
                <>
                  <UserOutlined
                    style={{ fontSize: `20px`, margin: `0 10px 0 0` }}
                  />
                  내 정보
                </>
              ),
            },
          ]}
        />
      </Wrapper>

      <Wrapper
        margin={`0 0 10px`}
        bgColor={colorConfigs.basic.white}
        shadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
        radius={`10px`}
        padding={`30px`}
        al={`flex-start`}
        ju={`flex-start`}
      >
        <Divider style={{ margin: `0 0 10px` }} orientation="left">
          사업장 정보
        </Divider>
        <Form
          size="large"
          style={{ width: `100%` }}
          form={userinfoForm}
          // onFinish={() => alert("comming soon...")}
          onFinish={userinfoUpdateHandler}
        >
          <FormItem
            name={`custnm`}
            label={`상호명`}
            // rules={[{ required: true, message: "상호명을 입력해 주세요." }]}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            style={{ width: `100%` }}
          >
            <Input readOnly />
          </FormItem>
          <Wrapper dr={`row`} ju={`space-between`}>
            <FormItem
              name={`rptnm`}
              label={`대표자 성명`}
              // rules={[
              //   { required: true, message: "대표자 성명을 입력해 주세요." },
              // ]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              style={{ width: `calc(100% / 2 - 5px)` }}
            >
              <Input readOnly />
            </FormItem>
            <FormItem
              name={`custid`}
              label={`사업자등록번호`}
              // rules={[
              //   { required: true, message: "사업자등록번호를 입력해 주세요." },
              // ]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              style={{ width: `calc(100% / 2 - 5px)` }}
            >
              <Input readOnly />
            </FormItem>
          </Wrapper>
          <Wrapper dr={`row`} ju={`space-between`}>
            <FormItem
              name={`zip1`}
              label={`우편번호`}
              rules={[{ required: true, message: "우편번호를 입력해 주세요." }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              style={{
                width:
                  width < 950
                    ? `calc(100% - 10px - 60px - 60px)`
                    : `calc(20% - 10px)`,
              }}
            >
              <Input readOnly />
            </FormItem>
            {width <= 950 && (
              <>
                <Wrapper ju={`flex-start`} width={`60px`} margin={`30px 0 0`}>
                  <Button
                    type="primary"
                    ghost
                    onClick={() => setAdrsModal((prev) => !prev)}
                    style={{ width: `60px` }}
                  >
                    검색
                  </Button>
                </Wrapper>
                <Wrapper ju={`flex-start`} width={`60px`} margin={`30px 0 0`}>
                  <Button
                    type="primary"
                    ghost
                    onClick={setAdrs}
                    style={{ width: `60px`, padding: `0` }}
                  >
                    기본값
                  </Button>
                </Wrapper>
              </>
            )}
            <FormItem
              name={`addr1`}
              label={`사업장 주소`}
              rules={[
                { required: true, message: "사업장 주소를 입력해 주세요." },
              ]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              style={{
                width: width < 950 ? `100%` : `calc(80% - 10px - 60px - 60px)`,
              }}
            >
              <Input readOnly />
            </FormItem>
            {width > 950 && (
              <>
                <Wrapper ju={`flex-start`} width={`60px`} margin={`30px 0 0`}>
                  <Button
                    type="primary"
                    ghost
                    onClick={() => setAdrsModal((prev) => !prev)}
                    style={{ width: `60px` }}
                  >
                    검색
                  </Button>
                </Wrapper>
                <Wrapper ju={`flex-start`} width={`60px`} margin={`30px 0 0`}>
                  <Button
                    type="primary"
                    ghost
                    onClick={setAdrs}
                    style={{ width: `60px`, padding: `0` }}
                  >
                    기본값
                  </Button>
                </Wrapper>
              </>
            )}
          </Wrapper>
          <Wrapper dr={`row`} ju={`space-between`}>
            <FormItem
              name={`offtel`}
              label={`전화번호`}
              rules={[
                { required: true, message: "전화번호를 입력해 주세요." },
                { validator: validateNumber },
              ]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              style={{ width: `calc(100% / 2 - 5px)` }}
            >
              <Input
                placeholder={`'-'을 제외한 11자리를 입력해 주세요`}
                maxLength={20}
              />
            </FormItem>
            <FormItem
              name={`offfax`}
              label={`FAX 번호`}
              rules={[
                { required: true, message: "FAX 번호를 입력해 주세요." },
                { validator: validateNumber },
              ]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              style={{ width: `calc(100% / 2 - 5px)` }}
            >
              <Input
                placeholder={`'-'을 제외하고 숫자만 입력해 주세요`}
                maxLength={20}
              />
            </FormItem>
          </Wrapper>
          <Wrapper al={`flex-end`}>
            <Button
              type="primary"
              ghost
              htmlType="submit"
              loading={userInfoLoading}
            >
              저장하기
            </Button>
          </Wrapper>
        </Form>
      </Wrapper>

      <Wrapper
        margin={`0 0 10px`}
        bgColor={colorConfigs.basic.white}
        shadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
        radius={`10px`}
        padding={`30px`}
        al={`flex-start`}
        ju={`flex-start`}
      >
        <Divider style={{ margin: `0 0 10px` }} orientation="left">
          로그인 정보
        </Divider>

        <Form
          size="large"
          style={{ width: `100%` }}
          form={idPwForm}
          // onFinish={() => alert("comming soon...")}
          onFinish={idPwUpdateHandler}
        >
          <Wrapper dr={`row`} ju={`space-between`}>
            <FormItem
              name={`user_id`}
              label={`사용자 아이디`}
              // rules={[
              //   { required: true, message: "사용자 아이디를 입력해 주세요." },
              //   { validator: validateUserid },
              // ]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              style={{ width: `calc(100% / 2 - 5px)` }}
            >
              <Input showCount maxLength={20} readOnly />
            </FormItem>
            <FormItem
              name={`origin_passwd`}
              label={`현재 비밀번호`}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              style={{ width: `calc(100% / 2 - 5px)` }}
            >
              <Input.Password readOnly />
            </FormItem>
          </Wrapper>
          <Wrapper dr={`row`} ju={`space-between`}>
            <FormItem
              name={`new_passwd`}
              label={`새 비밀번호`}
              rules={[
                {
                  required: true,
                  message: "새 비밀번호를 입력해 주세요.",
                },
                {
                  validator: (ruleObj, value) =>
                    validatePassword(ruleObj, value, "notcheck"),
                },
              ]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              style={{ width: `calc(100% / 2 - 5px)` }}
            >
              <Input.Password placeholder="새 비밀번호를 입력해 주세요." />
            </FormItem>
            <FormItem
              name={`passwd_check`}
              label={`새 비밀번호 확인`}
              rules={[
                {
                  required: true,
                  message: "새 비밀번호 확인을 입력해 주세요.",
                },
                {
                  validator: (ruleObj, value) =>
                    validatePassword(ruleObj, value, "check"),
                },
              ]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              style={{ width: `calc(100% / 2 - 5px)` }}
            >
              <Input.Password placeholder="새 비밀번호 확인을 입력해 주세요." />
            </FormItem>
          </Wrapper>
          <Wrapper al={`flex-end`}>
            <Button type="primary" ghost htmlType="submit">
              저장하기
            </Button>
          </Wrapper>
        </Form>
        <Modal
          open={adrsModal}
          title={`사업장 주소 검색`}
          footer={null}
          onCancel={() => setAdrsModal((prev) => !prev)}
          destroyOnClose
        >
          <DaumPostCode
            onComplete={adrsHandler}
            style={{ overflow: "hidden" }}
          />
        </Modal>
      </Wrapper>
    </Wrapper>
  );
};

export default MyInfo;
