import { BiHomeAlt } from "react-icons/bi";
import { RouteType } from "./config";
import { BsInfoCircleFill } from "react-icons/bs";
import { InboxOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import ProductList from "../pages/_mc1000/_mc1100/ProductList";
import Cart from "../pages/_mc1000/_mc1200/Cart";
import MyInfo from "../pages/_mc2000/_mc2200/MyInfo";
import MyPage from "../pages/_mc2000/_mc2100/MyPage";
import { baseURL } from "./routeConfig";
import Payment from "../pages/_mc3000/_mc3100/Payment";
import PaymentResult from "../pages/_mc3000/_mc3200/PaymentResult";
import PaymentMobile from "../pages/_mc3000/_mc3100/PaymentMobile";

const baseUrls = ["/mall/anna", "/mall/core"];

const appRoutes: RouteType[] = baseUrls.flatMap((url) => [
  {
    index: true,
    path: `${url}/sample`,
    element: <></>,
    state: "_siteMgmt",
    sidebarProps: {
      displayText: "샘플 페이지",
      icon: <BsInfoCircleFill />,
    },
  },
  {
    index: true,
    path: `${url}/main`,
    element: <></>,
    state: `_main`,
    sidebarProps: {
      displayText: `메인 페이지`,
      icon: <BiHomeAlt />,
    },
  },
  {
    index: true,
    path: `${url}/product/cart`,
    element: <Cart />,
    state: `_cart`,
    sidebarProps: {
      displayText: `장바구니`,
      icon: <ShoppingCartOutlined />,
    },
  },
  {
    index: true,
    path: `${url}/product/list`,
    element: <ProductList />,
    state: `_product`,
    sidebarProps: {
      displayText: `상품 목록`,
      icon: <InboxOutlined />,
    },
  },
  {
    index: true,
    path: `${url}/myinfo`,
    element: <MyInfo />,
    state: `_MyInfo`,
    sidebarProps: {
      displayText: `내 정보`,
      icon: <InboxOutlined />,
    },
  },
  {
    index: true,
    path: `${url}/mypage`,
    element: <MyPage />,
    state: `_MyPage`,
    sidebarProps: {
      displayText: `마이페이지`,
      icon: <InboxOutlined />,
    },
  },
  {
    index: true,
    path: `${url}/payment`,
    element: <Payment />,
    state: `_Pament`,
    sidebarProps: {
      displayText: `결제페이지`,
      icon: <InboxOutlined />,
    },
  },
  {
    index: true,
    path: `${url}/payment/mobile`,
    element: <PaymentMobile />,
    state: `_Pament`,
    sidebarProps: {
      displayText: `결제페이지`,
      icon: <InboxOutlined />,
    },
  },
  {
    index: true,
    path: `${url}/payment/result`,
    element: <PaymentResult />,
    state: `_Pament`,
    sidebarProps: {
      displayText: `결제 완료 페이지`,
      icon: <InboxOutlined />,
    },
  },
]);

export default appRoutes;
