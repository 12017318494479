import {
  Button,
  Checkbox,
  Descriptions,
  Form,
  Modal,
  Radio,
  Table,
  Tabs,
  Tag,
} from "antd";
import { AlignType } from "rc-table/lib/interface";
import { Wrapper } from "../../../components/common/commonComponents";
import colorConfigs from "../../../configs/colorConfigs";
import { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import DaumPostCode from "react-daum-postcode";
import {
  deliveryfeeFetcher,
  paygetcartinfoFetcher,
  paymentRegisterFetcher,
  preprocess,
  register,
  preprocessFetcher,
} from "../../../utils/fetcher";
import { useMutation, useQuery } from "react-query";
import { as_clnt, isTest } from "../../../configs/clientConfig";
import useWidth from "../../../hooks/useWidth";
import { useLocation, useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";
import { LoadNotification, numberWithCommas } from "../../../utils/commonUtils";
import useInput from "../../../hooks/useInput";
import { baseURL } from "../../../routes/routeConfig";

import "./pay.css";
import useOnlyNumberInput from "../../../hooks/useOnlyNumberInput";
declare global {
  interface Window {
    KCP_Pay_Execute_Web: any;
    closeEvent_SPAY: any;
  }
}

const format = "YYYY-MM-DD";
const CustomTag = styled(Tag)`
  margin: 0;
  padding: 10px 5px;
  font-size: 14px;
`;

const CustomTable = styled(Table)`
  & .ant-table-cell {
    /* background: red; */
  }
`;

const CustomTabs = styled(Tabs)`
  width: 100%;
`;

const FormItem = styled(Form.Item)`
  & .ant-form-item-label > label {
    width: 100%;
    &:after {
      display: none;
    }
  }
  & .ant-form-item-label > label > .labeltext {
    @media (max-width: 950px) {
      font-size: 12px;
    }
    font-size: 16px;
    width: 100%;
    text-align: left;
    font-weight: 700;
    color: #343434;
  }
`;

interface payDataInterface {
  rtn_code?: number;
  rtn_list?: payDataRtnList[];
  rtn_msg?: string;
}
interface payDataRtnList {
  addr?: string;
  addr_dtl?: string;
  custid?: string;
  custnm?: string;
  dlvfee?: number;
  email?: string;
  htmltext?: string;
  orderamt?: number;
  preamt?: number;
  receiver?: string;
  rectelno?: string;
  rtn_acctno?: string;
  rtn_banknm?: string;
  rtn_rptnm?: string;
  rtn_sublist?: payDataRtnSubList[];
  site_cd?: string;
  zip?: string;
}
interface payDataRtnSubList {
  rtn_colornm?: string;
  rtn_itm?: string;
  rtn_itmcd?: string;
  rtn_itmcdnm?: string;
  rtn_itmlist?: payDataRtnItmList[];
  rtn_ordamt?: number;
  rtn_ordprc?: number;
  rtn_ordqty?: number;
  rtn_recid?: number;
  rtn_sizenm?: string;
}
interface payDataRtnItmList {
  displaynm?: string;
  itm?: string;
  stock?: number;
  sznm?: string;
  szseq?: number;
}

const Payment = () => {
  const selectedArr = useLocation().state;
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const [payForm] = Form.useForm();
  const formRef = useRef<HTMLFormElement>(null);
  const formRef2 = useRef<HTMLFormElement>(null);
  const [ordable, setOrdable] = useState<boolean>(false);

  // const [dlv_type, setDlv_type] = useState("default");
  const [adrsModal, setAdrsModal] = useState<boolean>(false);
  const inputZip = useInput("");
  const inputAddr = useInput("");
  const inputAddrDtl = useInput("");
  const inputBuyr_name = useInput("");
  const inputBuyr_tel2 = useOnlyNumberInput("");
  const inputBuyr_mail = useInput("");

  const inputDlvMemo = useInput("");
  const [payMethod, setPayMethod] = useState<string>("010000000000");
  const [filteredPayData, setFilteredPayData] = useState<payDataRtnList>();
  const [useDtl, setUseDtl] = useState<boolean>(true);

  const movePage = useNavigate();

  const width = useWidth();
  const [modal, setModal] = useState(false);

  const [buyrName, setBuyrName] = useState("");
  const [buyrTel2, setBuyrTel2] = useState("");
  const [buyrMail, setBuyrMail] = useState("");

  const HTMLComponent = ({ htmlString }: { htmlString: string }) => {
    const sanitizedHTML = DOMPurify.sanitize(htmlString);

    return (
      <Wrapper
        border={`1px solid #eee`}
        fontSize="12px"
        dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
        color={colorConfigs.basic.grey1}
        lineHeight="1.8"
        style={{
          maxWidth: `950px`,
        }}
        al={`flex-start`}
        margin={`50px auto 0 auto`}
        padding={`20px`}
        radius="15px"
      />
    );
  };

  const myinfo = localStorage.getItem("SOWJDQH");

  const deliveryfeeFetcherHandler = useCallback(() => {
    let num = 0;
    if (filteredPayData?.rtn_sublist) {
      for (let i = 0; i < filteredPayData.rtn_sublist.length; i++) {
        num += filteredPayData.rtn_sublist[i].rtn_ordamt || 0;
      }
    }
    const _myinfo = myinfo && JSON.parse(myinfo);
    const qs = `?as_clnt=${as_clnt}&as_cust=${_myinfo?.rtn_ocust}&as_zipcode=${inputZip.value}&as_orderamt=${num}`;
    return deliveryfeeFetcher(qs);
  }, [filteredPayData, as_clnt, myinfo, inputZip]);

  const {
    data: payData,
    refetch,
    isLoading,
    isSuccess,
    isFetching,
  } = useQuery<payDataInterface>(
    ["paygetcartinfoFetcher", as_clnt, myinfo],
    () => {
      const _myinfo = myinfo && JSON.parse(myinfo);
      const qs = `?as_clnt=${as_clnt}&as_cust=${_myinfo?.rtn_ocust}`;
      return paygetcartinfoFetcher(qs);
    },
    {
      staleTime: Infinity,
      refetchInterval: false,
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        // data?.rtn_code === 0 && LoadNotification("[SYSTEM]", data?.rtn_msg,0);
      },
    }
  );

  const {
    data: deliveryfee,
    refetch: dlvRefetch,
    // isLoading,
    // isFetching,
  } = useQuery(
    ["deliveryfeeFetcher", as_clnt, myinfo, inputZip.value],
    deliveryfeeFetcherHandler,
    {
      enabled: false,
      staleTime: Infinity,
      refetchInterval: false,
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        // data?.rtn_code === 0 && LoadNotification("[SYSTEM]", data?.rtn_msg,0);
      },
    }
  );

  // use mutation

  const { mutate: registerMutate, isLoading: registerLoading } = useMutation(
    (data: register) => paymentRegisterFetcher(data),
    {
      onSuccess: (data) => {
        const url = data.PayUrl;
        call_pay_form();
        // window.open(url, "_blank", "noopener,noreferrer");
      },
    }
  );
  const { mutate: preprocessMutate, isLoading: preprocessLoading } =
    useMutation(async (data: preprocess) => await preprocessFetcher(data), {
      onSuccess: (data, variables) => preprocessSuccess(data, variables),
    });

  const getOS = () => {
    const userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
      windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
      iosPlatforms = ["iPhone", "iPad", "iPod"];

    if (macPlatforms.includes(platform)) {
      return "Mac OS";
    } else if (iosPlatforms.includes(platform)) {
      return "iOS";
    } else if (windowsPlatforms.includes(platform)) {
      return "Windows";
    } else if (/Android/.test(userAgent)) {
      return "Android";
    } else if (/Linux/.test(platform)) {
      return "Linux";
    }

    return "Unknown";
  };

  function call_pay_form(): void {
    const v_frm: HTMLFormElement | null = document.getElementById(
      "payForm"
    ) as HTMLFormElement | null;
    if (v_frm) {
      const PayUrl: string = (v_frm.PayUrl as HTMLInputElement).value;
      // 인코딩 방식에 따른 변경 -- Start
      if (v_frm.encoding_trans === undefined) {
        v_frm.action = PayUrl;
      } else {
        // encoding_trans "UTF-8" 인 경우
        if ((v_frm.encoding_trans as HTMLInputElement).value === "UTF-8") {
          v_frm.action =
            PayUrl.substring(0, PayUrl.lastIndexOf("/")) +
            "/jsp/encodingFilter/encodingFilter.jsp";
          (v_frm.PayUrl as HTMLInputElement).value = PayUrl;
        } else {
          v_frm.action = PayUrl;
        }
      }

      if ((v_frm.Ret_URL as HTMLInputElement).value === "") {
        /* Ret_URL값은 현 페이지의 URL 입니다. */
        alert("연동시 Ret_URL을 반드시 설정하셔야 됩니다.");
        return;
      } else {
        v_frm.submit();
      }
    }
  }

  const preprocessSuccess = useCallback(
    (data: any, variables: preprocess) => {
      if (data?.rtn_payreqid && formRef?.current) {
        if (variables.as_paymethod === "CARD") {
          if (isMobile) {
            const os = getOS();
            const site_cd = data?.rtn_site_cd;
            const ordr_idxx = data?.rtn_payreqid;
            if (
              filteredPayData?.rtn_sublist?.[0] &&
              typeof site_cd === "string" &&
              typeof ordr_idxx === "string"
            ) {
              const reqData = {
                as_clnt: variables.as_clnt,
                as_cust: variables.as_cust,
                site_cd: isTest ? "T0000" : site_cd,
                ordr_idxx: ordr_idxx,
                good_mny: String(variables?.as_totalamt),
                good_name:
                  (filteredPayData.rtn_sublist?.[0]?.rtn_itmcdnm || "") +
                  (filteredPayData?.rtn_sublist?.length > 1
                    ? ` 외 ${filteredPayData?.rtn_sublist?.length - 1}건`
                    : ""),
                pay_method: "CARD",
                Ret_URL: `https://mall.annapurna.co.kr/api/payment/callback`,
                escw_used: "N",
                user_agent: os,
              };
              registerMutate(reqData);
            }
          } else {
            jsf_pay(data?.rtn_payreqid, data.rtn_site_cd);
          }
        } else {
          acnoSuccess();
        }
      }
    },
    [isMobile, formRef, filteredPayData]
  );
  const jsf_pay = useCallback(
    (rtn_payreqid: string, rtn_site_cd: string) => {
      const _myinfo = myinfo && JSON.parse(myinfo);
      if (formRef2.current && _myinfo) {
        formRef2.current.as_clnt.value = as_clnt;
        formRef2.current.as_cust.value = _myinfo?.rtn_ocust;
      }
      if (
        formRef.current &&
        typeof rtn_payreqid === "string" &&
        typeof rtn_site_cd === "string"
      ) {
        try {
          let formElement = formRef.current;

          formElement.ordr_idxx.value = rtn_payreqid;
          formElement.ordr_idxx.value = rtn_payreqid;
          formElement.site_cd.value = isTest ? "T0000" : rtn_site_cd;
          const a = window.KCP_Pay_Execute_Web(formElement);
        } catch (error) {
          console.error(error);
          throw error;
        }
      }
    },
    [payForm, formRef.current, myinfo, isTest]
  );

  const adrsHandler = useCallback((e) => {
    inputZip.setValue(e.zonecode);
    inputAddr.setValue(e.address);
    setAdrsModal((prev) => !prev);
  }, []);

  useEffect(() => {
    if (isSuccess) {
      if (!selectedArr || selectedArr?.length === 0) {
        LoadNotification(
          "[SYSTEM]",
          "장바구니에서 상품 선택 후 구매하기를 클릭해주세요.",
          0
        );
        movePage(`${baseURL}/product/cart`);
      } else if (payData?.rtn_list && selectedArr) {
        const filtered_data = payData?.rtn_list[0]?.rtn_sublist?.filter(
          (item: payDataRtnSubList) => selectedArr.includes(item.rtn_recid)
        );

        if (filtered_data?.length === 0) {
          LoadNotification(
            "[SYSTEM]",
            "장바구니에서 상품 선택 후 구매하기를 클릭해주세요.",
            0
          );
          return movePage(`${baseURL}/product/cart`);
        }

        let num = 0;

        filtered_data?.forEach?.((item) => {
          if (item.rtn_ordamt) {
            return (num += item.rtn_ordamt);
          }
        });
        const filtered_pay_data = {
          ...payData?.rtn_list[0],
          orderamt: num,
          rtn_sublist: filtered_data,
        };

        setFilteredPayData(filtered_pay_data);
      } else {
        LoadNotification("[SYSTEM]", "잘못된 접근입니다.", 0);
        movePage(`${baseURL}/product/list`);
      }
    }
  }, [payData, selectedArr, isSuccess]);

  useEffect(() => {
    refetch();
  }, []);
  useEffect(() => {
    if (selectedArr) {
      if (isMobile) {
        movePage(`${baseURL}/payment/mobile`, { state: selectedArr });
      }
    }
  }, [selectedArr, isMobile]);

  useEffect(() => {
    if (filteredPayData) {
      const _filteredPayData = { ...filteredPayData };
      const list = _filteredPayData;
      let isOrderable = true;

      list?.rtn_sublist?.forEach((cart: payDataRtnSubList) => {
        if (cart?.rtn_itmlist) {
          const stock = cart.rtn_itmlist.find(
            (sl: any) => cart?.rtn_itm === sl.itm
          )?.stock;
          if (stock && cart?.rtn_ordqty && stock < cart.rtn_ordqty) {
            isOrderable = false;
          }
        }
      });

      setOrdable(isOrderable);
      if (!isOrderable) {
        LoadNotification("[SYSTEM]", "주문불가한 상품이 있습니다.", 0);
        return movePage(`${baseURL}/product/cart`);
      }
    }
  }, [filteredPayData]);

  useEffect(() => {
    if (
      filteredPayData?.rtn_sublist?.[0] &&
      formRef.current &&
      deliveryfee?.rtn_list?.[0]
    ) {
      const form = formRef.current;

      if (filteredPayData?.rtn_sublist?.length === 0) {
        LoadNotification("[SYSTEM]", "주문가능한 상품이 없습니다.", 0);
        return movePage(`${baseURL}/product/list`);
      }

      form.good_name.value =
        (filteredPayData.rtn_sublist?.[0]?.rtn_itmcdnm || "") +
        (filteredPayData?.rtn_sublist?.length > 1
          ? ` 외 ${filteredPayData?.rtn_sublist?.length - 1}건`
          : "");
      if (
        filteredPayData?.orderamt &&
        typeof filteredPayData?.preamt === "number"
      ) {
        form.good_mny.value =
          filteredPayData?.orderamt +
          deliveryfee?.rtn_list?.[0]?.deliveryamt -
          filteredPayData?.preamt;
      }
    }
  }, [deliveryfee, filteredPayData]);

  useEffect(() => {
    if (filteredPayData) {
      if (filteredPayData?.zip && (!inputZip || inputZip.value.trim() === "")) {
        inputZip.setValue(filteredPayData?.zip);
      }
      if (
        filteredPayData?.addr &&
        (!inputAddr || inputAddr.value.trim() === "")
      ) {
        inputAddr.setValue(filteredPayData?.addr);
      }
      if (
        filteredPayData?.addr_dtl &&
        (!inputAddrDtl || inputAddrDtl.value.trim() === "")
      ) {
        inputAddrDtl.setValue(filteredPayData?.addr_dtl);
      }
      if (
        filteredPayData?.receiver &&
        (!inputBuyr_name || inputBuyr_name.value.trim() === "")
      ) {
        inputBuyr_name.setValue(filteredPayData?.receiver);
      }
      if (
        filteredPayData?.rectelno &&
        (!inputBuyr_tel2 || inputBuyr_tel2.value.trim() === "")
      ) {
        inputBuyr_tel2.setValue(filteredPayData?.rectelno);
      }
      if (
        filteredPayData?.email &&
        (!inputBuyr_mail || inputBuyr_mail.value.trim() === "")
      ) {
        inputBuyr_mail.setValue(filteredPayData?.email);
      }
    }
  }, [filteredPayData]);

  useEffect(() => {
    if (inputZip && inputZip.value.trim() !== "") {
      dlvRefetch();
    }
  }, [inputZip.value, filteredPayData]);

  useEffect(() => {
    const _myinfo = myinfo && JSON.parse(myinfo);
    if (_myinfo) {
      setBuyrName(_myinfo?.rtn_ocustnm);
      setBuyrTel2(_myinfo?.rtn_offtel);
      setBuyrMail("");
    }
  }, [myinfo]);

  // const handleBeforeUnload = (event: BeforeUnloadEvent) => {
  //   event.preventDefault();
  //   const confirmationMessage =
  //     "정말 이 페이지를 떠나시겠습니까? 변경 사항은 저장되지 않을 수 있습니다.";
  //   event.returnValue = confirmationMessage;
  //   return confirmationMessage;
  // };
  // useEffect(() => {
  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);

  const preprocessMutateHandler = useCallback(() => {
    const _myinfo = myinfo && JSON.parse(myinfo);
    const as_cust = _myinfo?.rtn_ocust;
    if (
      filteredPayData?.rtn_sublist &&
      formRef.current &&
      deliveryfee?.rtn_list?.[0]
    ) {
      const list = filteredPayData?.rtn_sublist.map((dt: any) => ({
        as_recid: dt.rtn_recid,
        as_itm: dt.rtn_itm,
        as_ordqty: dt.rtn_ordqty,
        as_ordprc: dt.rtn_ordprc,
        as_ordamt: dt.rtn_ordamt,
      }));

      let as_paymethod = "";
      if (payMethod === "100000000000") {
        as_paymethod = "CARD";
      }
      if (payMethod === "010000000000") {
        as_paymethod = "ACNO";
      }
      if (payMethod === "010000000000tax") {
        as_paymethod = "ACNO_TAX";
      }

      if (!inputZip.value || inputZip.value.trim() === "") {
        return LoadNotification("[SYSTEM]", "우편번호를 입력해주세요.", 0);
      }
      if (!inputAddr.value || inputAddr.value.trim() === "") {
        return LoadNotification("[SYSTEM]", "배송지 주소를 입력해주세요.", 0);
      }
      if (useDtl && (!inputAddrDtl.value || inputAddrDtl.value.trim() === "")) {
        return LoadNotification("[SYSTEM]", "상세 주소를 입력해주세요.", 0);
      }
      if (!inputBuyr_name.value || inputBuyr_name.value.trim() === "") {
        return LoadNotification("[SYSTEM]", "수취인 이름을 입력해주세요.", 0);
      }
      if (!inputBuyr_tel2.value || inputBuyr_tel2.value.trim() === "") {
        return LoadNotification("[SYSTEM]", "수취인 연락처를 입력해주세요.", 0);
      }
      if (!inputBuyr_mail.value || inputBuyr_mail.value.trim() === "") {
        return LoadNotification("[SYSTEM]", "수취인 이메일을 입력해주세요.", 0);
      }
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValidEmail = regex.test(inputBuyr_mail.value);
      if (!isValidEmail) {
        return LoadNotification("[SYSTEM]", "이메일이 올바르지 않습니다.", 0);
      }
      if (
        typeof filteredPayData?.orderamt === "number" &&
        typeof filteredPayData?.preamt === "number" &&
        typeof deliveryfee?.rtn_list?.[0]?.deliveryamt === "number"
      ) {
        const data = {
          // 기본 정보
          as_clnt,
          as_cust,

          // 배송 정보
          as_zip: inputZip.value || "",
          as_addr: inputAddr.value || "",
          as_addr_dtl: inputAddrDtl.value || "",
          as_empnm: inputBuyr_name.value || "",
          as_telno: inputBuyr_tel2.value || "",
          as_email: inputBuyr_mail.value || "",
          as_dlvfee: deliveryfee?.rtn_list?.[0]?.deliveryamt,
          as_dlvrmk: inputDlvMemo.value || "",

          // 금액
          as_preamt: filteredPayData.preamt,
          as_totalamt:
            filteredPayData?.orderamt +
            deliveryfee?.rtn_list?.[0]?.deliveryamt -
            filteredPayData?.preamt,
          as_orderamt: filteredPayData.orderamt,

          // 결제수단
          as_paymethod,

          // 상품리스트
          as_jsondata: JSON.stringify(list),
        };

        if (payMethod === "100000000000" && data.as_totalamt < 100) {
          return LoadNotification(
            "[SYSTEM]",
            "카드결제는 결제금액 100원 이상부터 가능합니다.",
            0
          );
        }

        return preprocessMutate(data);
      }
    }
  }, [
    payMethod,
    filteredPayData,
    as_clnt,
    myinfo,
    inputZip,
    inputAddr,
    inputAddrDtl,
    inputBuyr_name,
    inputBuyr_tel2,
    inputBuyr_mail,
    deliveryfee,
    useDtl,
  ]);

  const acnoModalOpen = useCallback(() => {
    if (!inputZip.value || inputZip.value.trim() === "") {
      return LoadNotification("[SYSTEM]", "우편번호를 입력해주세요.", 0);
    }
    if (!inputAddr.value || inputAddr.value.trim() === "") {
      return LoadNotification("[SYSTEM]", "배송지 주소를 입력해주세요.", 0);
    }
    if (useDtl && (!inputAddrDtl.value || inputAddrDtl.value.trim() === "")) {
      return LoadNotification("[SYSTEM]", "상세 주소를 입력해주세요.", 0);
    }
    if (!inputBuyr_name.value || inputBuyr_name.value.trim() === "") {
      return LoadNotification("[SYSTEM]", "수취인 이름을 입력해주세요.", 0);
    }
    if (!inputBuyr_tel2.value || inputBuyr_tel2.value.trim() === "") {
      return LoadNotification("[SYSTEM]", "수취인 연락처를 입력해주세요.", 0);
    }
    if (!inputBuyr_mail.value || inputBuyr_mail.value.trim() === "") {
      return LoadNotification("[SYSTEM]", "수취인 이메일을 입력해주세요.", 0);
    }
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = regex.test(inputBuyr_mail.value);
    if (!isValidEmail) {
      return LoadNotification("[SYSTEM]", "이메일이 올바르지 않습니다.", 0);
    }
    setModal(true);
  }, [
    inputZip,
    inputAddr,
    inputAddrDtl,
    inputBuyr_name,
    inputBuyr_tel2,
    inputBuyr_mail,
    useDtl,
  ]);

  const acnoSuccess = useCallback(() => {
    movePage(`${baseURL}/payment/result?res_cd=${`0000`}&res_msg=${`성공`}`);
  }, []);

  const useDtlToggle = useCallback(() => {
    if (useDtl) {
      inputAddrDtl.setValue("");
    }
    setUseDtl((prev) => !prev);
  }, [useDtl]);

  const detailProductColumns = [
    {
      title: "상품 코드",
      align: "center" as AlignType,
      render: (data: payDataRtnSubList) => {
        return data?.rtn_itmcd;
      },
    },
    {
      title: "상품명",
      align: "center" as AlignType,
      render: (data: payDataRtnSubList) => {
        return data?.rtn_itmcdnm;
      },
    },
    {
      title: "색상",
      align: "center" as AlignType,
      render: (data: payDataRtnSubList) => {
        return data?.rtn_colornm;
      },
    },
    {
      title: "사이즈",
      align: "center" as AlignType,
      render: (data: payDataRtnSubList) => {
        return data?.rtn_sizenm;
      },
    },
    {
      title: "상품 금액",
      align: "center" as AlignType,
      render: (data: payDataRtnSubList) => {
        return data?.rtn_ordprc && numberWithCommas(data?.rtn_ordprc) + "원";
      },
    },
    {
      title: "수량",
      align: "center" as AlignType,
      render: (data: payDataRtnSubList) => {
        return data?.rtn_ordqty && numberWithCommas(data?.rtn_ordqty);
      },
    },
    {
      title: "총금액",
      align: "center" as AlignType,
      render: (data: payDataRtnSubList) => {
        return data?.rtn_ordamt && numberWithCommas(data?.rtn_ordamt) + "원";
      },
    },
  ];
  const detailProductColumnsForAcno = [
    {
      title: "항목",
      align: "center" as AlignType,
      render: (data: payDataRtnSubList) => {
        return data?.rtn_itmcdnm + " " + data?.rtn_colornm;
      },
    },
    {
      title: "사이즈",
      align: "center" as AlignType,
      render: (data: payDataRtnSubList) => {
        return data?.rtn_sizenm;
      },
    },
    {
      title: "상품 금액",
      align: "center" as AlignType,
      render: (data: payDataRtnSubList) => {
        return data?.rtn_ordprc && numberWithCommas(data?.rtn_ordprc) + "원";
      },
    },
    {
      title: "수량",
      align: "center" as AlignType,
      render: (data: payDataRtnSubList) => {
        return data?.rtn_ordqty && numberWithCommas(data?.rtn_ordqty);
      },
    },
    {
      title: "총금액",
      align: "center" as AlignType,
      render: (data: payDataRtnSubList) => {
        return data?.rtn_ordamt && numberWithCommas(data?.rtn_ordamt) + "원";
      },
    },
  ];
  return (
    <Wrapper al={`flex-start`} ju={`flex-start`} height={`100%`}>
      <Wrapper
        width={`100%`}
        margin={`0 0 50px`}
        radius={`10px`}
        shadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
        overflow="hidden"
        bgColor={colorConfigs.basic.white}
        padding={`20px`}
      >
        <body>
          <form
            name="order_info"
            ref={formRef}
            id={`payForm`}
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className="wrap">
              <div className="header">
                <h1 className="title">주문/결제</h1>
                {/* {JSON.stringify(selectedArr)} */}
              </div>
              <div id="skipCont" className="contents">
                <h2 className="title-type-3">주문내역</h2>

                <Table
                  style={{ margin: width < 950 ? `10px 0 0` : `14px 0 0` }}
                  dataSource={filteredPayData?.rtn_sublist || []}
                  columns={detailProductColumns}
                  size={`small`}
                  pagination={{
                    pageSize: 5,
                    showSizeChanger: false,
                  }}
                  scroll={{ x: 950 }}
                />

                <ul style={{ display: `none` }} className="list-type-1">
                  <li>
                    <div className="left">
                      <p className="title">상품명</p>
                    </div>
                    <div className="right">
                      <div className="ipt-type-1 pc-wd-2">
                        <input type="text" name="good_name" readOnly />
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="left">
                      <p className="title">주문 금액</p>
                    </div>
                    <div className="right">
                      <div className="ipt-type-1 pc-wd-2">
                        <input
                          type="text"
                          hidden
                          name="good_mny"
                          style={{ textAlign: `right` }}
                        />
                        <Wrapper
                          style={{ maxWidth: `360px` }}
                          al={`flex-end`}
                          height={width < 950 ? `30px` : `40px`}
                          fontSize={`14px`}
                          bgColor="#F5F5F5"
                          padding={
                            width < 950
                              ? `5px 29px 5px 9px`
                              : `10px 29px 10px 9px`
                          }
                        >
                          {filteredPayData &&
                            typeof filteredPayData?.orderamt === "number" &&
                            typeof deliveryfee?.rtn_list?.[0]?.deliveryamt ===
                              "number" &&
                            typeof filteredPayData?.preamt === "number" &&
                            numberWithCommas(
                              filteredPayData?.orderamt +
                                deliveryfee?.rtn_list?.[0]?.deliveryamt -
                                filteredPayData?.preamt
                            )}
                        </Wrapper>
                        <span className="txt-price">원</span>
                      </div>
                    </div>
                  </li>
                </ul>
                <div className="line-type-1"></div>
                <h2 className="title-type-3">주문 정보</h2>
                <ul className="list-type-1">
                  <li>
                    <div className="left">
                      <p className="title">우편번호</p>
                    </div>

                    <div className="right">
                      <div className="ipt-type-1 pc-wd-2">
                        <input
                          type="text"
                          readOnly
                          {...inputZip}
                          placeholder="검색버튼을 통해 주소를 선택해 주세요."
                          maxLength={10}
                          style={{
                            width: `calc(100% - 60px - 5px)`,
                            margin: `0 5px 0 0`,
                          }}
                        />
                        <Button
                          type="primary"
                          ghost
                          onClick={() => setAdrsModal((prev) => !prev)}
                          style={{ width: `60px` }}
                          size={width < 950 ? `small` : `large`}
                        >
                          검색
                        </Button>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="left">
                      <p className="title">배송지 주소</p>
                    </div>
                    <div className="right">
                      <div className="ipt-type-1 pc-wd-2">
                        <input
                          type="text"
                          readOnly
                          {...inputAddr}
                          placeholder="검색버튼을 통해 주소를 선택해 주세요."
                          maxLength={600}
                          style={{
                            width: `calc(100%)`,
                            margin: `0 5px 0 0`,
                          }}
                        />
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="left">
                      <p className="title">상세주소</p>
                    </div>
                    <div className="right">
                      <div
                        className="ipt-type-1 pc-wd-2"
                        style={{
                          display: `flex`,
                          justifyContent: `space-between`,
                          alignItems: `center`,
                        }}
                      >
                        <input
                          type="text"
                          {...inputAddrDtl}
                          maxLength={100}
                          style={{
                            width: `calc(100% - 120px)`,
                            // margin: `0 5px 0 0`,
                          }}
                          readOnly={!useDtl}
                          placeholder={
                            useDtl ? "상세주소를 입력해 주세요." : ""
                          }
                        />
                        <Checkbox checked={!useDtl} onChange={useDtlToggle}>
                          상세주소 없음
                        </Checkbox>
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="left">
                      <p className="title">수취인 이름</p>
                    </div>
                    <div className="right">
                      <div className="ipt-type-1 pc-wd-2">
                        <input
                          type="text"
                          // name=""
                          {...inputBuyr_name}
                          placeholder={`수취인 이름을 입력해 주세요.`}
                          maxLength={100}
                        />
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="left">
                      <p className="title">수취인 연락처</p>
                    </div>
                    <div className="right">
                      <div className="ipt-type-1 pc-wd-2">
                        <input
                          type="text"
                          // name=""
                          {...inputBuyr_tel2}
                          placeholder={`'-'을 제외한 11자리를 입력해 주세요`}
                          maxLength={20}
                        />
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="left">
                      <p className="title">수취인 이메일</p>
                    </div>
                    <div className="right">
                      <div className="ipt-type-1 pc-wd-2">
                        <input
                          type="text"
                          // name=""
                          placeholder={`수취인 이메일을 입력해 주세요.`}
                          {...inputBuyr_mail}
                          maxLength={100}
                        />
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="left">
                      <p className="title">배송시 요청사항</p>
                    </div>
                    <div className="right">
                      <div className="ipt-type-1 pc-wd-2">
                        <textarea
                          rows={4}
                          {...inputDlvMemo}
                          maxLength={100}
                          placeholder="배송시 요청사항을 입력해 주세요."
                        />
                      </div>
                    </div>
                  </li>
                </ul>
                <div className="line-type-1"></div>
                <h2 className="title-type-3" style={{ display: `flex` }}>
                  결제 금액
                  <Wrapper
                    width={`auto`}
                    fontSize={width < 950 ? `12px` : "14px"}
                    al={`flex-start`}
                    color={`#919191`}
                    fontWeight="700"
                    margin="0 0 0 10px"
                  >
                    (결제금액 = 주문금액 + 배송비 - 선수금)
                  </Wrapper>
                </h2>
                <ul className="list-type-1">
                  <li>
                    <div className="left">
                      <p className="title">주문 금액</p>
                    </div>
                    <div className="right">
                      <div className="ipt-type-1 pc-wd-2">
                        <input
                          type="text"
                          readOnly
                          style={{ textAlign: `right` }}
                          value={
                            typeof filteredPayData?.orderamt === "number"
                              ? numberWithCommas(filteredPayData.orderamt)
                              : "0"
                          }
                        />
                        <span className="txt-price">원</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="left">
                      <p className="title">배송비</p>
                    </div>
                    <div className="right">
                      <div className="ipt-type-1 pc-wd-2">
                        <input
                          type="text"
                          style={{ textAlign: `right` }}
                          readOnly
                          value={
                            deliveryfee?.rtn_list?.[0]?.deliveryamt === 0
                              ? "0"
                              : numberWithCommas(
                                  deliveryfee?.rtn_list?.[0]?.deliveryamt
                                ) + ""
                          }
                        />
                        <span className="txt-price">원</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="left">
                      <p className="title">선수금</p>
                    </div>
                    <div className="right">
                      <div className="ipt-type-1 pc-wd-2">
                        <input
                          type="text"
                          style={{ textAlign: `right` }}
                          readOnly
                          value={
                            filteredPayData && filteredPayData.preamt === 0
                              ? 0 + ""
                              : filteredPayData &&
                                numberWithCommas(filteredPayData.preamt) + ""
                          }
                        />
                        <span className="txt-price">원</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="left">
                      <p className="title">결제금액</p>
                    </div>
                    <div className="right">
                      <div className="ipt-type-1 pc-wd-2">
                        <input
                          type="text"
                          style={{ textAlign: `right` }}
                          readOnly
                          value={
                            typeof filteredPayData?.orderamt === "number" &&
                            typeof filteredPayData?.preamt === "number" &&
                            deliveryfee &&
                            numberWithCommas(
                              filteredPayData.orderamt +
                                deliveryfee?.rtn_list?.[0]?.deliveryamt -
                                filteredPayData.preamt
                            ) + ""
                          }
                        />
                        <span className="txt-price">원</span>
                      </div>
                    </div>
                  </li>
                </ul>
                <div className="line-type-1"></div>
                <h2 className="title-type-3">결제수단</h2>

                <ul className="list-check-1">
                  <li>
                    <input
                      type="radio"
                      id="radio-2-1"
                      className="ipt-radio-1"
                      name="pay_method"
                      value="100000000000"
                      checked={payMethod === "100000000000"}
                      onChange={(event) => setPayMethod(event.target.value)}
                    />
                    <label htmlFor="radio-2-1">
                      <span className="ico-radio">
                        <span></span>
                      </span>
                      신용카드
                    </label>
                  </li>
                  <li>
                    <input
                      type="radio"
                      id="radio-2-2"
                      className="ipt-radio-1"
                      name="pay_method"
                      value="010000000000"
                      checked={payMethod === "010000000000"}
                      onChange={(event) => setPayMethod(event.target.value)}
                    />
                    <label htmlFor="radio-2-2">
                      <span className="ico-radio">
                        <span></span>
                      </span>
                      계좌이체
                    </label>
                  </li>
                  <li>
                    <input
                      type="radio"
                      id="radio-2-3"
                      className="ipt-radio-1"
                      name="pay_method"
                      value="010000000000tax"
                      checked={payMethod === "010000000000tax"}
                      onChange={(event) => setPayMethod(event.target.value)}
                    />
                    <label htmlFor="radio-2-3">
                      <span className="ico-radio">
                        <span></span>
                      </span>
                      계좌이체(세금계산서)
                    </label>
                  </li>
                </ul>

                <div className="Line-Type-1"></div>
                <ul className="list-btn-2">
                  <li
                    className="btn-type-2 pc-wd-3"
                    onClick={() =>
                      filteredPayData && payMethod === "100000000000"
                        ? preprocessMutateHandler()
                        : acnoModalOpen()
                    }
                    style={{ margin: width < 950 ? `0 0 10px` : `0` }}
                  >
                    결제요청
                  </li>
                  <li
                    className="pc-only-show btn-type-3 pc-wd-2"
                    onClick={() => movePage(`${baseURL}/product/list`)}
                  >
                    처음으로
                  </li>
                </ul>
              </div>
              <input type="hidden" name="buyr_name" value={buyrName} />
              <input type="hidden" name="buyr_tel2" value={buyrTel2} />
              <input type="hidden" name="buyr_mail" value={buyrMail} />
              <input type="hidden" name="ordr_idxx" />
              <input type="hidden" name="site_cd" />

              <input
                type="hidden"
                name="site_name"
                value={as_clnt === "10000" ? "안나푸르나" : "코어알파인"}
              />
              <input type="hidden" name="pay_method" value="" />
              <input type="hidden" name="res_cd" value="" />
              <input type="hidden" name="res_msg" value="" />
              <input type="hidden" name="enc_info" value="" />
              <input type="hidden" name="enc_data" value="" />
              <input type="hidden" name="ret_pay_method" value="" />
              <input type="hidden" name="tran_cd" value="" />
              <input type="hidden" name="use_pay_method" value="" />
              <input type="hidden" name="ordr_chk" value="" />
              <input type="hidden" name="cash_yn" value="" />
              <input type="hidden" name="cash_tr_code" value="" />
              <input type="hidden" name="cash_id_info" value="" />
            </div>
            <button hidden type="submit" id="paySubmit" />
          </form>
          <form name="basic_data" ref={formRef2} id={`basicForm`}>
            <input name="as_clnt" type="hidden" value={as_clnt || ""} />
            <input
              name="as_cust"
              type="hidden"
              value={(myinfo && JSON.parse(myinfo)?.rtn_ocust) || ""}
            />

            <input
              name="ordr_mony"
              value={
                typeof filteredPayData?.orderamt === "number" &&
                typeof deliveryfee?.rtn_list?.[0]?.deliveryamt === "number" &&
                typeof filteredPayData?.preamt === "number"
                  ? filteredPayData.orderamt +
                    deliveryfee?.rtn_list?.[0]?.deliveryamt -
                    filteredPayData.preamt
                  : 0
              }
              type="hidden"
            />
          </form>
          {filteredPayData?.htmltext && (
            <HTMLComponent htmlString={filteredPayData?.htmltext} />
          )}
          {/* <Wrapper>
            <Text
              margin={`20px 0 0`}
              fontSize={`12px`}
              color={colorConfigs.basic.grey1}
            >
              ⓒ ANNAPURNA
              ⓒ 코어알파인
            </Text>
          </Wrapper> */}
        </body>
      </Wrapper>
      <Modal
        open={adrsModal}
        title={`배송지 주소 검색`}
        footer={null}
        destroyOnClose
        onCancel={() => setAdrsModal((prev) => !prev)}
      >
        <DaumPostCode onComplete={adrsHandler} style={{ overflow: "hidden" }} />
      </Modal>
      <Modal
        centered
        open={modal}
        title={
          payMethod === "010000000000"
            ? `계좌이체`
            : payMethod === "010000000000tax"
            ? "계좌이체(세금계산서)"
            : ""
        }
        closable={false}
        footer={
          <Wrapper dr={`row`} ju={`flex-end`}>
            <Button
              style={{ margin: `0 8px 0 0` }}
              loading={preprocessLoading}
              onClick={() => {
                setModal(false);
              }}
            >
              주문취소
            </Button>

            <Button
              type="primary"
              loading={preprocessLoading}
              onClick={() => {
                preprocessMutateHandler();
              }}
            >
              주문요청
            </Button>
          </Wrapper>
        }
        okText={`주문하기`}
        cancelText={`취소하기`}
        destroyOnClose
      >
        <Table
          style={{ margin: width < 950 ? `10px 0 0` : `14px 0 0` }}
          dataSource={filteredPayData?.rtn_sublist || []}
          columns={detailProductColumnsForAcno}
          size={`small`}
          pagination={{
            pageSize: 5,
            showSizeChanger: false,
          }}
          scroll={{ x: 470 }}
        />
        <Descriptions bordered size="small">
          <Descriptions.Item span={24} label="주문 금액">
            {typeof filteredPayData?.orderamt === "number"
              ? numberWithCommas(filteredPayData.orderamt)
              : "0"}
            원
          </Descriptions.Item>
          <Descriptions.Item span={24} label="배송비">
            {deliveryfee?.rtn_list?.[0]?.deliveryamt === 0
              ? 0 + ""
              : numberWithCommas(deliveryfee?.rtn_list?.[0]?.deliveryamt) + ""}
            원
          </Descriptions.Item>
          <Descriptions.Item span={24} label="선수금">
            {filteredPayData && filteredPayData.preamt === 0
              ? 0 + ""
              : filteredPayData &&
                numberWithCommas(filteredPayData.preamt) + ""}
            원
          </Descriptions.Item>

          <Descriptions.Item span={24} label="결제 금액">
            {typeof filteredPayData?.orderamt === "number" &&
              typeof filteredPayData?.preamt === "number" &&
              deliveryfee &&
              numberWithCommas(
                filteredPayData.orderamt +
                  deliveryfee?.rtn_list?.[0]?.deliveryamt -
                  filteredPayData.preamt
              ) + ""}
            원
          </Descriptions.Item>
          {as_clnt === "10000" ? (
            <Descriptions.Item span={24} label="계좌번호">
              우리은행 1005-401-349802
            </Descriptions.Item>
          ) : (
            <Descriptions.Item span={24} label="계좌번호">
              우리은행 1005-201-307234
            </Descriptions.Item>
          )}
          {as_clnt === "10000" ? (
            <Descriptions.Item span={24} label="예금주">
              안나푸르나
            </Descriptions.Item>
          ) : (
            <Descriptions.Item span={24} label="예금주">
              코어알파인
            </Descriptions.Item>
          )}
        </Descriptions>
      </Modal>
    </Wrapper>
  );
};

export default Payment;
