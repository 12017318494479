const sizeConfigs = {
  sidebarEnable: {
    width: "350px",
  },
  sidebarDisable: {
    width: "200px",
  },
};

export default sizeConfigs;
