import { notification } from "antd";
import { useNavigate } from "react-router-dom";
import { baseURL } from "../routes/routeConfig";

export const numberWithCommas = (x?: string | number) => {
  return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "";
};

export const LoadNotification = (
  msg: string,
  content: string,
  status: number,
  maxCount?: number
) => {
  const notiType = status === 1 ? "success" : "error";
  notification.config({
    maxCount: maxCount || 1, // 최대로 표시되는 알림의 갯수를 1로 설정
  });
  notification[notiType]({
    message: msg,
    description: content,
    onClick: () => {},
  });
};

export const LoadNotificationForSessionError = (
  msg: string,
  content: string,
  status: number
) => {
  const notiType = status === 1 ? "success" : "error";
  const movePage = useNavigate();
  movePage(`${baseURL}/login`);
  notification.config({
    maxCount: 1, // 최대로 표시되는 알림의 갯수를 1로 설정
  });
  notification[notiType]({
    message: msg,
    description: content,
    onClick: () => {},
  });
};
