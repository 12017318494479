import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import MainLayout from "./components/layout/MainLayout";
import { routes } from "./routes";
import React from "react";
import { ThemeProvider } from "styled-components";
import colorConfigs from "./configs/colorConfigs";
import _moduleLogin from "./pages/_public/_loginMgmt/_moduleLogin";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CookiesProvider } from "react-cookie";
import { sRoutes as subRoutes } from "./routes/subRoutes";
import ProductList from "./pages/_mc1000/_mc1100/ProductList";
import { ConfigProvider } from "antd";
import { baseURL } from "./routes/routeConfig";
import AxiosNavigation from "./utils/AxiosIntercepter";

function App() {
  return (
    <ThemeProvider theme={colorConfigs}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#6200EE",
            colorError: "#B00020",
          },

          components: {
            Button: {
              dangerColor: "#B00020",
              colorLink: "#304FFE",
            },
          },
        }}
      >
        <CookiesProvider>
          <BrowserRouter>
            <AxiosNavigation>
              <Routes>
                <Route path={`${baseURL}/login`} element={<_moduleLogin />} />
                {/* <Route element={<MainLayout />}>
              <Route element={<ProductList />} path="/product" />
            </Route> */}
                <Route
                  path="/"
                  element={<Navigate to={`${baseURL}/login`} />}
                />
                <Route
                  path={baseURL}
                  element={<Navigate to={`${baseURL}/login`} />}
                />
                <Route element={<MainLayout />}>{subRoutes}</Route>
                <Route element={<MainLayout />}>{routes}</Route>
              </Routes>
            </AxiosNavigation>
          </BrowserRouter>
        </CookiesProvider>
        {/*<ReactQueryDevtools initialIsOpen={false} />*/}
      </ConfigProvider>
    </ThemeProvider>
  );
}

export default App;
