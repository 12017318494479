import colorConfigs from "../../../configs/colorConfigs";
import { Button, Drawer, ImageProps } from "antd";
import { useRecoilValue, useSetRecoilState } from "recoil";
import React, { FC, useState } from "react";
import { Image, Text, Wrapper } from "../commonComponents";
import { isToggleAtomState } from "../../../state/systemInit";
import {
  EditOutlined,
  LoginOutlined,
  MenuFoldOutlined,
  MenuOutlined,
  MenuUnfoldOutlined,
  ShoppingCartOutlined,
  UserOutlined,
} from "@ant-design/icons";
import useWidth from "../../../hooks/useWidth";
import styled from "styled-components";
import { useNavigate } from "react-router";
import { as_clnt } from "../../../configs/clientConfig";
import _sidebar from "../Sidebar/_sidebar";
import { baseURL } from "../../../routes/routeConfig";
import { useQueryClient } from "react-query";

const CustomDrawer = styled(Drawer)`
  & .ant-drawer-body {
    padding: 0;
  }
`;

const HoverWrapper = styled(Wrapper)`
  &:hover {
    background-color: ${colorConfigs.basic.bwhite};
  }
`;

const _topbar = () => {
  const queryClient = useQueryClient();
  const myinfo = localStorage.getItem("SOWJDQH");
  const _myinfo = myinfo && JSON.parse(myinfo);
  const width = useWidth();
  const isToggle = useRecoilValue(isToggleAtomState);
  const setToggleAtom = useSetRecoilState(isToggleAtomState);
  const toggle = () => {
    setToggleAtom((prev) => ({ toggle: prev ? !prev.toggle : true }));
  };

  const [isDrawer, setIsDrawer] = useState<boolean>(false);
  const isMobile = /Mobi|Android/i.test(navigator.userAgent);

  const movePage = useNavigate();
  return (
    <Wrapper
      zIndex="99"
      top={`0`}
      right={`0`}
      position={`fixed`}
      shadow="0px 2px 4px rgba(0, 0, 0, 0.1)" // Adding a subtle shadow
      bgColor={`#3700B3`} // A slightly different shade than white
      color={colorConfigs.basic.white}
      dr={`row`}
      ju={`space-between`}
      height={`64px`}
      borderBottom="1px solid rgba(0, 0, 0, 0.1)" // Adding a border at the bottom
    >
      <Wrapper dr={`row`} width={`auto`} height={`100%`}>
        {width > 950 && (
          <HoverWrapper
            onClick={toggle}
            fontSize="24px"
            width={`65px`}
            height={`100%`}
            cursor="pointer"
          >
            {isToggle ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
          </HoverWrapper>
        )}
        <Wrapper dr={`row`} width={`auto`} margin={`0 0 0 10px`}>
          <Wrapper
            width={width < 950 ? `150px` : `200px`}
            onClick={() => movePage(`${baseURL}/product/list`)}
          >
            {as_clnt === "10000" ? (
              <Image
                src={`https://mall.annapurna.co.kr/b2b/images/anna_logo.png`}
              />
            ) : (
              <Image
                src={`https://mall.annapurna.co.kr/b2b/images/core_logo.png`}
              />
            )}
          </Wrapper>

          {width > 950 ? (
            <Wrapper
              width={`auto`}
              margin={`0 0 0 10px`}
              fontWeight="900"
              dr={`row`}
            >
              {as_clnt === "10000" ? `ANNAPURNA` : `코어알파인`} B2B SYSTEM /
              &nbsp;{(myinfo && _myinfo?.rtn_ocustnm) || ""}
            </Wrapper>
          ) : (
            <Text margin={`0 0 0 10px`}>
              {(myinfo && _myinfo?.rtn_ocustnm) || ""}
            </Text>
          )}
        </Wrapper>
      </Wrapper>

      {width < 950 ? (
        <HoverWrapper
          onClick={() => setIsDrawer(true)}
          fontSize="24px"
          width={`65px`}
          height={`100%`}
          cursor="pointer"
        >
          <MenuOutlined />
        </HoverWrapper>
      ) : (
        <Wrapper dr={`row`} width={`auto`} padding={`0 60px 0 0`}>
          <Button
            style={{ color: colorConfigs.basic.white }}
            type={`text`}
            icon={<ShoppingCartOutlined />}
            onClick={() => movePage(`${baseURL}/product/cart`)}
          >
            장바구니
          </Button>
          <Button
            style={{ color: colorConfigs.basic.white }}
            type={`text`}
            icon={<UserOutlined />}
            onClick={() => movePage(`${baseURL}/mypage`)}
          >
            마이페이지
          </Button>
          <Button
            style={{ color: colorConfigs.basic.white }}
            type={`text`}
            icon={<EditOutlined />}
            onClick={() => movePage(`${baseURL}/myinfo`)}
          >
            내 정보
          </Button>
          <Button
            style={{ color: colorConfigs.basic.white }}
            type={`text`}
            icon={<LoginOutlined />}
            onClick={() => {
              movePage(`${baseURL}/login`);
              queryClient.setQueryData("getUserInfoFetcher", null);
              localStorage.removeItem("SOWJDQH");
            }}
          >
            로그아웃
          </Button>
        </Wrapper>
      )}
      <CustomDrawer
        open={isDrawer}
        width={`100%`}
        onClose={() => setIsDrawer(false)}
        title={
          <Wrapper al={`flex-end`}>
            <Button
              style={{ color: colorConfigs.basic.black, fontSize: `16px` }}
              type={`text`}
              icon={<LoginOutlined style={{ fontSize: `16px` }} />}
              onClick={() => {
                movePage(`${baseURL}/login`);
                queryClient.setQueryData("getUserInfoFetcher", null);
                localStorage.removeItem("SOWJDQH");
              }}
            >
              로그아웃
            </Button>
          </Wrapper>
        }
      >
        <Wrapper height={`100%`} bgColor={`#041428`} ju={`flex-start`}>
          <_sidebar isDrawer={true} />
        </Wrapper>
      </CustomDrawer>
    </Wrapper>
  );
};

export default _topbar;
