import React, { useCallback, useEffect, useState } from "react";
import {
  Wrapper,
  Text,
  Image,
  SpanText,
} from "../../../components/common/commonComponents";
import styled from "@emotion/styled";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  message,
  notification,
} from "antd";
import colorConfigs from "../../../configs/colorConfigs";
import useWidth from "../../../hooks/useWidth";
import { useMutation, useQuery } from "react-query";
import { loginData, loginFetcher } from "../../../utils/fetcher";
import axios from "axios";
import { as_clnt } from "../../../configs/clientConfig";
import { useNavigate } from "react-router-dom";
import { baseURL } from "../../../routes/routeConfig";
import { LoadNotification } from "../../../utils/commonUtils";

const CustomInput = styled(Input)`
  border-radius: 4px;
  border: 1px solid rgba(var(--sk_foreground_high_solid, 134, 134, 134), 1);
  transition: border 80ms ease-out, box-shadow 80ms ease-out;
  box-sizing: border-box;
  margin: 0 0 20px;
  width: 100%;
  max-width: 100%;
  color: rgba(var(--sk_primary_foreground, 29, 28, 29), 1);
  background-color: rgba(var(--sk_primary_background, 255, 255, 255), 1);
  padding: 12px;
  height: 44px;
  padding-top: 11px;
  padding-bottom: 13px;
  font-size: 18px;
  line-height: 1.33333333;

  &:focus {
    border: 1px solid rgba(var(--sk_highlight, 18, 100, 163), 1);
    box-shadow: 0 0 0 1px rgba(var(--sk_highlight, 18, 100, 163), 1),
      0 0 0 5px rgba(29, 155, 209, 0.3);
  }
`;

const CustomButton = styled(Button)`
  width: 100%;
  font-size: 18px;
  font-weight: 900;
  height: 44px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
`;

const _moduleLogin = () => {
  const width = useWidth();
  const [ip, setIp] = useState("");
  const [selectValue, setSelectValue] = useState("http://");
  const [currentUrl, setCurrentUrl] = useState(
    "http://211.234.106.53:8080/ords/annapurna/api"
  );
  const movePage = useNavigate();
  const { mutate, isLoading } = useMutation({
    mutationFn: (data: loginData) => {
      data.as_clnt = as_clnt;
      data.as_ipaddr = ip;
      data.as_pcinfo = "";
      return loginFetcher(data);
    },
    onSuccess: (data) => {
      if (data?.rtn_code === -1) {
        localStorage.setItem("SOWJDQH", "");
      }
      if (data?.rtn_code === 0) {
        localStorage.setItem("SOWJDQH", "");
        LoadNotification("[SYSTEM]", data.rtn_msg, 0);
      }

      if (data?.rtn_code === 1) {
        localStorage.setItem(
          "SOWJDQH",
          JSON.stringify({ ...data, offtel: "" })
        );
        movePage(`${baseURL}/product/list`);
      }
    },
  });

  useEffect(() => {
    const url = localStorage.getItem("baseUrl");
    url && setCurrentUrl(url);
    axios.get("https://geolocation-db.com/json/").then((res) => {
      setIp(res.data.IPv4);
    });
  }, []);

  const urlChanger = useCallback(
    (e: string) => {
      const url = selectValue + e;
      localStorage.setItem("baseUrl", url);
      setCurrentUrl(url);
      return LoadNotification("[SYSTEM]", "Base URL이 적용되었습니다.", 1);
    },
    [selectValue]
  );
  const selectBefore = (
    <Select
      value={selectValue}
      onSelect={(e) => setSelectValue(e)}
      defaultValue="http://"
    >
      <Select.Option value="http://">http://</Select.Option>
      <Select.Option value="https://">https://</Select.Option>
    </Select>
  );

  return (
    <Wrapper>
      <Wrapper
        bgColor={colorConfigs.basic.white}
        height={`100dvh`}
        bgImg={
          as_clnt === "10000"
            ? `url("https://mall.annapurna.co.kr/b2b/images/anna_back.jpg")`
            : `url("https://mall.annapurna.co.kr/b2b/images/core_back.jpg")`
        }
      >
        {/* {typeof window !== "undefined" && window.navigator.userAgent} */}
        {/* <Text
          textAlign="right"
          width={`80%`}
          margin={`0 0 10px`}
          padding={`0 20px 0 0`}
          fontSize="20px"
        >
          현재 Base Url :&nbsp;
          {currentUrl}
        </Text> */}

        <Wrapper
          dr={`row`}
          bgColor={`rgba(255, 255, 255, 0.8)`}
          shadow={`rgba(17, 12, 46, 0.15) 0px 48px 100px 0px`}
          width={`auto`}
          padding={`30px`}
          radius={`10px`}
        >
          <Row justify="center" gutter={[16, 24]}>
            <Col
              xl={12}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              {width > 1200 ? (
                <Wrapper padding={`30px`}>
                  <Image
                    src={
                      as_clnt === "10000"
                        ? `https://mall.annapurna.co.kr/b2b/images/anna_aptc_logo.png`
                        : `https://mall.annapurna.co.kr/b2b/images/core_beal_logo.jpg`
                    }
                    alt={`logo`}
                  />
                  <Text fontSize="16px" fontWeight="700" margin={`10px 0 0`}>
                    {as_clnt === "10000" ? `ANNAPURNA` : `코어알파인`} B2B
                    SYSTEM
                  </Text>
                </Wrapper>
              ) : null}
            </Col>
            <Col xl={12} style={{ marginTop: "20px", marginBottom: "20px" }}>
              {/* <Input.Search
                style={{ margin: 0 }}
                enterButton={<Button>적용</Button>}
                addonBefore={selectBefore}
                onSearch={(e: string) => urlChanger(e)}
              /> */}

              <Form onFinish={(data: loginData) => mutate(data)}>
                <Form.Item
                  name="as_userid"
                  label={<Text fontSize={`16px`}>아이디</Text>}
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  style={{ margin: `0 0 20px` }}
                  rules={[
                    { required: true, message: "아이디를 입력해주세요." },
                  ]}
                >
                  <CustomInput style={{ margin: 0 }} />
                </Form.Item>

                <Form.Item
                  name="as_userpw"
                  label={<Text fontSize={`16px`}>비밀번호</Text>}
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  style={{ margin: `0 0 20px` }}
                  rules={[
                    { required: true, message: "비밀번호를 입력해주세요." },
                  ]}
                >
                  <CustomInput type="password" style={{ margin: 0 }} />
                </Form.Item>
                <CustomButton
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                >
                  로그인
                </CustomButton>
              </Form>
            </Col>
          </Row>
        </Wrapper>
      </Wrapper>
    </Wrapper>
  );
};

export default _moduleLogin;
