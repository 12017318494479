import React from "react";
import { Text, Wrapper } from "../commonComponents";
import { Button, Layout } from "antd";

import colorConfigs from "../../../configs/colorConfigs";
import { GENERAL } from "../../../configs/userTypeConfig";
import useWidth from "../../../hooks/useWidth";
import { useQuery } from "react-query";
import { footerFetcher } from "../../../utils/fetcher";
import { as_clnt } from "../../../configs/clientConfig";
import DOMPurify from "dompurify";
import {
  EditOutlined,
  HomeFilled,
  HomeOutlined,
  LoginOutlined,
  ShoppingCartOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { baseURL } from "../../../routes/routeConfig";

const AppFooter = () => {
  const { Footer } = Layout;
  const width = useWidth();
  const { data } = useQuery("footerFetcher", () => footerFetcher(as_clnt));
  const movePage = useNavigate();

  const HTMLComponent = () => {
    const htmlString = DOMPurify.sanitize(data?.rtn_info);
    const sanitizedHTML = DOMPurify.sanitize(htmlString);

    return (
      <Wrapper
        dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
        style={{ color: colorConfigs.basic.grey1, lineHeight: 1.5 }}
      />
    );
  };
  if (width < 950) {
    return (
      <Wrapper
        dr={`row`}
        ju={`space-around`}
        width={`100%`}
        height={`64px`}
        position={`fixed`}
        zIndex="10"
        bottom={`0`}
        bgColor={colorConfigs.basic.white}
        shadow="0px -2px 5px 0px rgba(0, 0, 0, 0.1)"
        padding={`0 20px`}
      >
        <Wrapper cursor={`pointer`} width={`auto`}>
          <HomeOutlined
            style={{ fontSize: "30px", margin: `0 0 5px` }}
            onClick={() => movePage(`${baseURL}/product/list`)}
          />
          <Text fontSize={`12px`}>메인</Text>
        </Wrapper>
        <Wrapper
          cursor={`pointer`}
          width={`auto`}
          onClick={() => movePage(`${baseURL}/product/cart`)}
        >
          <ShoppingCartOutlined
            style={{ fontSize: "30px", margin: `0 0 5px` }}
          />
          <Text fontSize={`12px`}> 장바구니</Text>
        </Wrapper>
        <Wrapper
          cursor={`pointer`}
          width={`auto`}
          onClick={() => movePage(`${baseURL}/mypage`)}
        >
          <UserOutlined style={{ fontSize: "30px", margin: `0 0 5px` }} />
          <Text fontSize={`12px`}>마이페이지</Text>
        </Wrapper>
        <Wrapper
          cursor={`pointer`}
          width={`auto`}
          onClick={() => movePage(`${baseURL}/myinfo`)}
        >
          <EditOutlined style={{ fontSize: "30px", margin: `0 0 5px` }} />
          <Text fontSize={`12px`}>내 정보</Text>
        </Wrapper>
      </Wrapper>
    );
  } else {
    return (
      <Footer
        style={{
          width: "100%",
          height: `220px`,
          padding: 0,
          color: "white",
        }}
      >
        <Wrapper height={`100%`} fontSize={width ? `13px` : `14px`}>
          <HTMLComponent />
        </Wrapper>
      </Footer>
    );
  }
};

export default AppFooter;
