import { useRecoilValue, useSetRecoilState } from "recoil";
import sizeConfigs from "../../../configs/sizeConfigs";
import { Menu } from "antd";
import { Wrapper } from "../commonComponents";
import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { isToggleAtomState } from "../../../state/systemInit";
import { useQuery } from "react-query";
import { getCategoryFetcher } from "../../../utils/fetcher";
import { as_clnt } from "../../../configs/clientConfig";
import { BoxPlotOutlined } from "@ant-design/icons";
import { currentCategoryState } from "../../../state/category";
import { baseURL } from "../../../routes/routeConfig";

const SideMenu = styled(Menu)`
  overflow-y: auto;
  padding: 20px 0 0;
`;

export interface Category {
  rtn_level: number;
  rtn_b2bcls: string;
  rtn_b2bclsnm: string;
  leaf_node: string;
  first_rtn_b2bcls: string;
}

interface CategoryMenu {
  rtn_level: number;
  rtn_b2bcls: string;
  rtn_b2bclsnm: string;
  leaf_node: string;
  first_rtn_b2bcls: string;
  children: CategoryMenu[];
}

const _sidebar = ({ isDrawer }: { isDrawer?: boolean }) => {
  const [menus, setMenus] = useState<ReactNode[] | null>(null);

  const currentCategory = useRecoilValue(currentCategoryState);
  const setCurrentCategory = useSetRecoilState(currentCategoryState);

  const isToggle = useRecoilValue(isToggleAtomState);
  const movePage = useNavigate();
  const { data: categoryData } = useQuery("getCategoryFetcher", () =>
    getCategoryFetcher(`?as_clnt=${as_clnt}`)
  );

  const renderMenu = useCallback(
    (routes: CategoryMenu[]) => {
      return routes.map((data, index) => {
        const qs = `?as_b2bcls=${data?.rtn_b2bcls ? data.rtn_b2bcls : ""}`;

        if (data.children && data.children.length !== 0) {
          return (
            <Menu.SubMenu
              icon={<BoxPlotOutlined />}
              key={data.rtn_b2bcls}
              title={data.rtn_b2bclsnm}
              onTitleClick={() => {
                if (data?.rtn_level !== 1) {
                  movePage(`${baseURL}/product/list${qs}`);
                }
              }}
            >
              {renderMenu(data.children)}
            </Menu.SubMenu>
          );
        } else {
          return (
            <Menu.Item icon={<BoxPlotOutlined />} key={data.rtn_b2bcls}>
              {/*<Badge.Ribbon text={"beta"}>*/}
              <Link to={`${baseURL}/product/list${qs}`}>
                {data.rtn_b2bclsnm}
              </Link>

              {/*</Badge.Ribbon>*/}
            </Menu.Item>
          );
        }
      });
    },
    [isToggle?.toggle, isDrawer]
  );

  const convertToCategoryMenu = (categories: Category[]): CategoryMenu[] => {
    const categoryMap: Record<string, CategoryMenu> = {};

    categories.forEach((category) => {
      if (category.rtn_level === 1) {
        categoryMap[category.rtn_b2bcls] = {
          ...category,
          children: [],
        };
      }
    });

    // 부모-자식 관계를 통해 맵에 추가
    categories.forEach((category) => {
      if (category.rtn_level > 1) {
        const parentKey = category.rtn_b2bcls.slice(0, -1);
        const parent = categoryMap[parentKey];
        if (parent) {
          const childMenu: CategoryMenu = {
            ...category,
            children: [],
          };
          parent.children.push(childMenu);
          categoryMap[category.rtn_b2bcls] = childMenu;
        }
      }
    });

    // 맵에서 배열로 변환하여 반환
    return Object.values(categoryMap).filter((menu) => menu.rtn_level === 1);
  };

  const location = useLocation();
  useEffect(() => {
    if (categoryData?.rtn_code === 1) {
      const result = convertToCategoryMenu(categoryData?.rtn_list);

      setCurrentCategory(categoryData?.rtn_list);
      const viewMenu = renderMenu(result);
      setMenus(viewMenu);
    }
  }, [categoryData, isToggle?.toggle, isDrawer]);

  return (
    <Wrapper
      width={
        isDrawer
          ? `100%`
          : isToggle?.toggle === true
          ? sizeConfigs.sidebarEnable.width
          : sizeConfigs.sidebarDisable.width
      }
      ju={`flex-start`}
      height={`calc(100dvh - 64px)`}
      position="fixed"
      top={`64px`}
      left={`0`}
    >
      <Wrapper ju="flex-start" overflow={`hidden`} height={`100%`}>
        <SideMenu
          style={{ width: `100%`, height: `100%` }}
          mode={isToggle?.toggle ? "inline" : "vertical"}
          // inlineCollapsed={isDrawer ? false : !isToggle?.toggle}
          theme={`dark`}
          defaultSelectedKeys={[location?.pathname]}
          defaultOpenKeys={["sub1"]}
        >
          {menus && menus}
        </SideMenu>
      </Wrapper>
    </Wrapper>
  );
};
export default _sidebar;
