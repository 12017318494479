import { RouteType } from "./config";
import React, { ReactNode } from "react";
import { Route } from "react-router-dom";
import { Wrapper } from "../components/common/commonComponents";

export const subRoutes: RouteType[] = [
  // sub-contractor
  // ----------------------------
];

const generateRoute = (routes: RouteType[]): ReactNode => {
  return routes.map((route, index) =>
    route.index ? (
      <Route
        index
        path={route.path}
        element={<Wrapper>{route.element}</Wrapper>}
        key={index}
      />
    ) : (
      <Route
        path={route.path}
        element={<Wrapper>{route.element}</Wrapper>}
        key={index}
      >
        {route.child && generateRoute(route.child)}
      </Route>
    )
  );
};

export const sRoutes: ReactNode = generateRoute(subRoutes);
