import React, { useEffect, useState } from "react";

const useMyinfo = () => {
  const [value, setValue] = useState(localStorage.getItem("SOWJDQH"));
  useEffect(() => {
    setValue(localStorage.getItem("SOWJDQH"));
  }, []);

  return { value, setValue };
};

export default useMyinfo;
