import {
  Breadcrumb,
  Button,
  Image as AntdImage,
  Input,
  Table,
  Tag,
  Select,
  message,
  InputNumber,
  Form,
  Popconfirm,
} from "antd";
import { Text, Wrapper } from "../../../components/common/commonComponents";
import { LoadingOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import colorConfigs from "../../../configs/colorConfigs";
import { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { AlignType } from "rc-table/lib/interface";
import useWidth from "../../../hooks/useWidth";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import {
  deleteCartFetcher,
  getCartFetcher,
  updateCartFetcher,
} from "../../../utils/fetcher";
import { as_clnt } from "../../../configs/clientConfig";
import { ColumnsType } from "antd/es/table";
import { LoadNotification, numberWithCommas } from "../../../utils/commonUtils";
import { baseURL } from "../../../routes/routeConfig";

// 임시 데이터
const as_jobgb = "CART";

interface Cart {
  rtn_colornm?: number;
  img_m?: string;
  img_s?: string;
  rtn_itm?: string;
  rtn_itmcd?: string;
  rtn_itmcdnm?: string;
  rtn_itmid?: number;
  rtn_ordamt?: number;
  rtn_ordprc?: number;
  rtn_ordqty?: number;
  rtn_recdt?: string;
  rtn_recid?: number;
  rtn_rtlprc?: number;
  rtn_salprc?: number;
  rtn_sizenm?: number;
  rtn_makernm?: string;
  rtn_originnm?: string;
  rtn_sublist: CartSub[];
}
interface CartSub {
  displaynm: string;
  itm?: string;
  stock: number;
  sznm?: string;
  szseq?: number;
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: "input" | "select";
  record: Cart;
  index: number;
  children: React.ReactNode;
}

const CustomTable = styled(Table)`
  & .ant-table-cell {
    /* background: red; */
  }
`;

const Cart = () => {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent); // 안드로이드 아이폰을 검사해 체크
  const [form] = Form.useForm();
  const qtyValue = Form.useWatch("rtn_ordqty", form);
  const displaynmValue = Form.useWatch("rtn_displaynm", form);

  const [updateData, setUpdateData] = useState<Cart | null>(null);
  const [mode, setMode] = useState<"view" | "edit">("view");
  const [list, setList] = useState<Cart[] | []>([]);
  const [originList, setOriginList] = useState<Cart[] | []>([]);
  const [amt, setAmt] = useState<number>(0);
  const [checkedAmt, setCheckedAmt] = useState<number>(0);
  const [ordable, setOrdable] = useState<boolean>(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const width = useWidth();
  const movePage = useNavigate();
  const myinfo = localStorage.getItem("SOWJDQH");

  const [previewWidthVisible, setPreviewWidthVisible] = useState(0);

  // const handlePreview = () => {
  //   setTimeout(() => {
  //     setPreviewWidthVisible(600);
  //   }, 500);
  // };
  const handlePreviewClose = (e: boolean) => {
    if (e === true) {
      setTimeout(() => {
        setPreviewWidthVisible(600);
      }, 400);
    } else {
      setPreviewWidthVisible(0);
    }
  };

  const {
    data,
    refetch,
    isLoading: isGetLoading,
    isSuccess: isGetSuccess,
  } = useQuery(
    "getCartFetcher",
    () =>
      getCartFetcher(
        `?as_clnt=${as_clnt}&as_jobgb=${as_jobgb}&as_cust=${
          myinfo ? JSON.parse(myinfo)?.rtn_ocust : ""
        }`
      ),
    {
      staleTime: Infinity,
      refetchInterval: false,
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess(dt) {
        setList(dt?.rtn_list || []);
      },
    }
  );

  const {
    mutate: dMutate,
    isSuccess,
    isLoading,
  } = useMutation(deleteCartFetcher, {
    onSuccess: (_, variables) => {
      refetch();
      LoadNotification("[SYSTEM]", "장바구니 내역이 삭제되었습니다.", 1);
    },
  });

  const { mutate: uMutate, isLoading: cartUpdateLoading } = useMutation(
    updateCartFetcher,
    {
      onSuccess: () => {
        refetch();
        LoadNotification("[SYSTEM]", "장바구니 내역이 수정되었습니다.", 1);
        setMode("view");
      },
    }
  );

  const edit = (data: Cart) => {
    form.setFieldsValue({
      rtn_displaynm: data.rtn_itm,
      rtn_ordqty: data.rtn_ordqty,
    });
    setUpdateData(data);
  };

  const save = useCallback(
    (data) => {
      const stock = data?.rtn_sublist?.find(
        (dt: CartSub) => dt.itm === displaynmValue
      )?.stock;

      if (typeof qtyValue === "number" && typeof stock === "number") {
        if (qtyValue > stock) {
          return LoadNotification(
            "[SYSTEM]",
            `주문가능수량을 초과했습니다. 가능수량은 ${stock} EA 입니다.`,
            0
          );
        }
        form.submit();
      }
    },
    [form, qtyValue, displaynmValue]
  );

  const cartDeleteOne = useCallback(
    (data) => {
      const _myinfo = myinfo && JSON.parse(myinfo);

      const req_Data = {
        as_clnt,
        as_cust: _myinfo?.rtn_ocust,
        as_jsondata: JSON.stringify([
          {
            clnt: as_clnt,
            recid: data?.rtn_recid,
          },
        ]),
      };
      dMutate(req_Data);
    },
    [myinfo]
  );

  const cartDeleteAll = useCallback(() => {
    if (data && data?.rtn_list) {
      const _myinfo = myinfo && JSON.parse(myinfo);

      const map_data = data?.rtn_list?.map((dt: Cart) => ({
        clnt: as_clnt,
        recid: dt?.rtn_recid,
      }));

      const as_jsondata = JSON.stringify(map_data);
      const req_Data = {
        as_clnt,
        as_cust: _myinfo?.rtn_ocust,
        as_jsondata,
      };

      dMutate(req_Data);
    }
  }, [myinfo, data]);

  const updateSubmit = useCallback(() => {
    const _myinfo = myinfo && JSON.parse(myinfo);

    const isError = [...list].some((item: Cart) => {
      const stock = item?.rtn_sublist.find(
        (dt: CartSub) => dt.itm === item.rtn_itm
      )?.stock;
      if (typeof item.rtn_ordqty === "number" && typeof stock === "number")
        return (item.rtn_ordqty || 0) === stock;
    });
    const isError2 = [...list].some((item: Cart) => {
      const stock = item?.rtn_sublist.find(
        (dt: CartSub) => dt.itm === item.rtn_itm
      )?.stock;
      if (typeof item.rtn_ordqty === "number" && typeof stock === "number")
        return (item.rtn_ordqty || 0) > stock;
    });

    if (isError) {
      return LoadNotification("[SYSTEM]", `품절된 상품이 있습니다.`, 0);
    }
    if (isError2) {
      return LoadNotification(
        "[SYSTEM]",
        `주문가능수량을 초과한 상품이 있습니다.`,
        0
      );
    }

    const jsondata = [...list].map((data) => {
      return {
        clnt: as_clnt,
        recid: data.rtn_recid,
        itm: data.rtn_itm,
        ordqty: data.rtn_ordqty,
        ordprc: data.rtn_ordprc,
      };
    });

    const req_Data = {
      as_clnt,
      as_cust: _myinfo?.rtn_ocust,
      as_jsondata: JSON.stringify([...jsondata]),
    };

    uMutate(req_Data);
  }, [as_clnt, myinfo, updateData, list]);

  const sizeHandler = useCallback(
    (e, data: Cart) => {
      const _list = [...list];
      const selectdata = JSON.parse(e);
      const __list = _list.map((dt) => {
        if (dt.rtn_recid === data.rtn_recid) {
          return {
            ...dt,
            rtn_itm: selectdata?.rtn_itm,
            rtn_sizenm: selectdata?.rtn_sizenm,
          };
        } else {
          return dt;
        }
      });
      setList(__list);
    },
    [list]
  );
  const qtyHandler = useCallback(
    (e, data: Cart) => {
      const _myinfo = myinfo && JSON.parse(myinfo);
      const _list = [...list];
      const __list = _list.map((dt) => {
        if (dt.rtn_recid === data.rtn_recid) {
          return {
            ...dt,
            rtn_ordqty: e,
          };
        } else {
          return dt;
        }
      });

      const filtered_data = __list?.filter(
        (item) =>
          typeof item.rtn_recid === "number" &&
          selectedRowKeys.includes(item.rtn_recid)
      );
      let num = 0;
      filtered_data.forEach((data: Cart) => {
        if (typeof data?.rtn_salprc === "number" && data?.rtn_ordqty) {
          num += data?.rtn_ordqty * data?.rtn_salprc;
        }
      });
      setCheckedAmt(num);
      setList(__list);

      const jsondata = __list.map((data) => {
        return {
          clnt: as_clnt,
          recid: data.rtn_recid,
          itm: data.rtn_itm,
          ordqty: data.rtn_ordqty,
          ordprc: data.rtn_ordprc,
        };
      });

      const req_Data = {
        as_clnt,
        as_cust: _myinfo?.rtn_ocust,
        as_jsondata: JSON.stringify([...jsondata]),
      };

      uMutate(req_Data);
    },
    [as_clnt, myinfo, updateData, list, selectedRowKeys]
  );

  const ableFetcher = useCallback(
    (_: any, data: Cart) => {
      if (!updateData) {
        const stock = data?.rtn_sublist?.find(
          (dt) => dt.itm === data.rtn_itm
        )?.stock;
        if (typeof data?.rtn_ordqty === "number" && typeof stock === "number")
          return data?.rtn_ordqty > stock ? (
            <Tag color={`#B00020`}>
              <Text>주문 불가능</Text>
            </Tag>
          ) : (
            <Tag>
              <Text>주문 가능</Text>
            </Tag>
          );
      } else {
        const stock = data?.rtn_sublist?.find(
          (dt) => dt.itm === displaynmValue
        )?.stock;
        if (typeof qtyValue === "number" && typeof stock === "number")
          return qtyValue > stock ? (
            <Tag>
              <Text>주문 불가능</Text>
            </Tag>
          ) : (
            <Tag>
              <Text>주문 가능</Text>
            </Tag>
          );
      }
    },
    [qtyValue, displaynmValue]
  );

  const dataFinder = useCallback(
    (data) => {
      return list.find((dt) => dt.rtn_recid === data.rtn_recid);
    },
    [list]
  );

  const editMode = useCallback(() => {
    setMode("edit");
    setOriginList(list);
  }, [list]);

  const cancel = useCallback(() => {
    setMode("view");
    setList(originList);

    setOriginList([]);
  }, [originList]);

  const goToPayment = useCallback(() => {
    if (selectedRowKeys) {
      if (isMobile) {
        movePage(`${baseURL}/payment/mobile`, { state: selectedRowKeys });
      } else {
        movePage(`${baseURL}/payment`, { state: selectedRowKeys });
      }
    }
  }, [selectedRowKeys, movePage]);

  useEffect(() => {
    if (isSuccess) {
      refetch();
    }
  }, [isSuccess]);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    setList(data?.rtn_list || []);
  }, [data]);

  useEffect(() => {
    let num = 0;
    list.forEach((data: Cart) => {
      if (
        typeof data?.rtn_ordqty === "number" &&
        typeof data?.rtn_ordprc === "number"
      )
        num += data?.rtn_ordqty * data?.rtn_ordprc;
    });
    setAmt(num);
  }, [list]);

  const handleBeforeUnload = (event: BeforeUnloadEvent) => {
    event.preventDefault();
    const confirmationMessage =
      "정말 이 페이지를 떠나시겠습니까? 변경 사항은 저장되지 않을 수 있습니다.";
    event.returnValue = confirmationMessage;
    return confirmationMessage;
  };

  const onSelectChange = useCallback(
    (e: any) => {
      setSelectedRowKeys(e);

      const filtered_data = list?.filter((item) => e.includes(item.rtn_recid));
      let num = 0;
      filtered_data.forEach((data: Cart) => {
        if (typeof data?.rtn_salprc === "number" && data?.rtn_ordqty) {
          num += data?.rtn_ordqty * data?.rtn_salprc;
        }
      });
      setCheckedAmt(num);
    },
    [list]
  );

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (list) {
      const _list = [...list];
      let isOrderable = true;
      const filtered_list = _list?.filter(
        (item: Cart) =>
          typeof item.rtn_recid === "number" &&
          selectedRowKeys.includes(item.rtn_recid)
      );
      filtered_list.forEach((cart: Cart) => {
        const stock = cart.rtn_sublist.find(
          (sl: any) => cart?.rtn_itm === sl.itm
        )?.stock;
        if (stock && cart?.rtn_ordqty && stock < cart.rtn_ordqty) {
          isOrderable = false;
        }
      });
      setOrdable(isOrderable);
    }
  }, [list, selectedRowKeys]);
  const ref = useRef();
  const columns = [
    {
      title: "이미지",
      dataIndex: "rtn_img_s",
      width: width < 950 ? 100 : 150,
      align: "center" as AlignType,
      render: (_: any, data: Cart) => {
        return (
          <AntdImage
            src={data?.img_s}
            fallback="/images/image.png"
            preview={{
              src: data?.img_m,
              width: width < 950 ? `80%` : previewWidthVisible,
              destroyOnClose: true,
              onVisibleChange: (e) => handlePreviewClose(e),
            }}
          />
        );
      },
    },

    {
      title: "상품코드",
      align: "center" as AlignType,
      dataIndex: "rtn_itmcd",
      render: (_: any, data: Cart) => {
        return <Text>{data?.rtn_itmcd}</Text>;
      },
    },
    {
      title: "품명 / 제조사 / 원산지",
      dataIndex: "rtn_itmcdnm",
      align: "center" as AlignType,
      render: (_: any, data: Cart) => {
        return (
          <Wrapper al={`flex-start`} ju={`flex-start`}>
            <Text>
              {data?.rtn_itmcdnm} {data?.rtn_colornm}
            </Text>
            <Text>{data?.rtn_makernm}</Text>
            <Text>{data?.rtn_originnm}</Text>
          </Wrapper>
        );
      },
    },
    {
      title: "소비자가",
      dataIndex: "rtn_rtlprc",
      align: "center" as AlignType,
      render: (_: any, data: Cart) => {
        return <Text>{numberWithCommas(data?.rtn_rtlprc)}원</Text>;
      },
    },
    {
      title: "판매가",
      dataIndex: "rtn_salprc",
      align: "center" as AlignType,
      render: (_: any, data: Cart) => {
        return <Text>{numberWithCommas(data?.rtn_salprc)}원</Text>;
      },
    },
    {
      title: "사이즈",
      dataIndex: "rtn_displaynm",
      align: "center" as AlignType,
      editable: true,
      render: (_: any, data: Cart) => {
        const rtn_displaynm = data?.rtn_sublist?.find(
          (dt) => dt.itm === data.rtn_itm
        )?.displaynm;
        if (mode === "view") {
          return <Text>{rtn_displaynm}</Text>;
        } else {
          const inputdata = dataFinder(data);

          const value = JSON.stringify({
            rtn_itm: inputdata?.rtn_itm,
            rtn_sizenm: inputdata?.rtn_sizenm,
          });

          return (
            <Select
              style={{ width: `120px` }}
              onChange={(e) => sizeHandler(e, data)}
              value={value}
            >
              {data?.rtn_sublist?.map?.((dt: CartSub) => {
                return (
                  <Select.Option
                    value={JSON.stringify({
                      rtn_itm: dt.itm,
                      rtn_sizenm: dt.sznm,
                    })}
                    disabled={dt.stock === 0}
                  >
                    {dt.displaynm}
                  </Select.Option>
                );
              })}
            </Select>
          );
        }
      },
    },

    {
      title: "수량",
      dataIndex: "rtn_ordqty",
      align: "center" as AlignType,
      render: (_: any, data: Cart) => {
        const inputdata = dataFinder(data);

        return (
          <Wrapper>
            {cartUpdateLoading || isGetLoading ? (
              <Wrapper width={`90px`}>
                <LoadingOutlined />
              </Wrapper>
            ) : (
              <InputNumber
                style={{ width: `90px` }}
                onStep={(e) => !isMobile && qtyHandler(e, data)}
                onBlur={(e) =>
                  parseInt(e.target.value) !== inputdata?.rtn_ordqty &&
                  qtyHandler(parseInt(e.target.value), data)
                }
                value={inputdata?.rtn_ordqty}
                min={1}
                defaultValue={data.rtn_ordqty}
              />
            )}
          </Wrapper>
        );
      },
    },
    {
      title: "총금액",
      dataIndex: "rtn_ordqty",
      align: "center" as AlignType,
      render: (_: any, data: Cart) => {
        if (data?.rtn_ordqty && data?.rtn_salprc) {
          const num = data?.rtn_ordqty * data?.rtn_salprc;
          return <Text>{numberWithCommas(num)}원</Text>;
        }
      },
    },
    {
      title: "주문 가능 여부",
      dataIndex: "rtn_able",
      align: "center" as AlignType,
      render: ableFetcher,
    },
    {
      title: "",
      dataIndex: "fns",
      align: "center" as AlignType,
      width: "90px",
      onlyView: true,
      render: (data: any, record: Cart) =>
        mode === "view" && (
          <Popconfirm
            title="[SYSTEM]"
            description="장바구니 내역을 삭제하시겠습니까?"
            placement="topLeft"
            onConfirm={() =>
              !isLoading && list.length !== 0 && cartDeleteOne(record)
            }
            okButtonProps={{ size: `middle` }}
            cancelButtonProps={{ size: "middle" }}
          >
            <Button danger ghost loading={isLoading}>
              삭제하기
            </Button>
          </Popconfirm>
        ),
    },
  ];

  // const mergedColumns = columns
  //   .map((col) => {
  //     if (!col.onlyView) {
  //       return col;
  //     }
  //     if (mode === "view") {
  //       return col;
  //     } else {
  //       return;
  //     }
  //   })
  //   .filter(Boolean);

  return (
    <Wrapper al={`flex-start`} ju={`flex-start`} height={`100%`}>
      <Wrapper
        dr={`row`}
        ju={`space-between`}
        bgColor={colorConfigs.basic.white}
        shadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
        padding={width < 950 ? `15px` : `30px`}
        radius={`10px`}
        margin={`0 0 50px`}
      >
        <Breadcrumb
          items={[
            {
              title: (
                <>
                  <ShoppingCartOutlined
                    style={{ fontSize: `20px`, margin: `0 10px 0 0` }}
                  />
                  장바구니
                  {/* {JSON.stringify(selectedRowKeys)} */}
                </>
              ),
            },
          ]}
        />
      </Wrapper>
      <Wrapper
        dr={`row`}
        ju={`flex-end`}
        margin={`0 0 10px`}
        padding={`0 16px 0 0`}
      >
        {mode === "view" ? (
          // <Button
          //   type={`primary`}
          //   ghost
          //   onClick={editMode}
          //   disabled={list.length === 0}
          // >
          //   상품 옵션 전체 수정
          // </Button>
          <></>
        ) : (
          <Wrapper dr={`row`} ju={`space-between`}>
            <Text color={`#B00020`} fontSize="18px" margin={`0 0 10px`}>
              * 저장하지 않으면 변경사항이 적용되지 않습니다.
            </Text>
            <Wrapper dr={`row`} width={`auto`}>
              <Button
                type={`primary`}
                ghost
                style={{ margin: `0 10px 0 0` }}
                onClick={updateSubmit}
              >
                저장
              </Button>
              <Button danger ghost onClick={cancel}>
                취소
              </Button>
            </Wrapper>
          </Wrapper>
        )}
      </Wrapper>
      <Wrapper
        radius={`10px`}
        shadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
        overflow="hidden"
        bgColor={colorConfigs.basic.white}
      >
        <Wrapper>
          <Table
            style={{ width: `100%` }}
            columns={columns}
            rowSelection={{
              selectedRowKeys,
              onChange: onSelectChange,
              getCheckboxProps: (record) => ({
                disabled: false, // 필요한 경우 체크박스를 비활성화할 수 있습니다.
              }),
            }}
            dataSource={list.map((data, index) => ({
              ...data,
              key: data.rtn_recid,
            }))}
            loading={isGetLoading}
            scroll={{ x: 1300 }}
          />

          <Wrapper dr={`row`} ju={`space-between`}>
            {list && list.length !== 0 && (
              <Wrapper
                al={`flex-end`}
                fontSize={width < 950 ? `16px` : "20px"}
                margin={`30px 0 15px 0`}
                padding={`0 15px 0 0`}
              >
                <Text margin={`0 0 5px`}>
                  장바구니 금액 : {numberWithCommas(amt)}원
                </Text>
                <Text>
                  선택 항목 금액 :&nbsp;
                  {checkedAmt === 0 ? "0" : numberWithCommas(checkedAmt)}원
                </Text>
              </Wrapper>
            )}

            <Wrapper
              cursor="pointer"
              color={colorConfigs.basic.white}
              width={`calc(100% / 3 - 3px)`}
              height={`50px`}
              bgColor={colorConfigs.themeColor.theme2}
              onClick={() => movePage(`${baseURL}/product/list`)}
            >
              쇼핑 계속 하기
            </Wrapper>

            <Popconfirm
              title="[SYSTEM]"
              description="장바구니 내역을 삭제하시겠습니까?"
              okButtonProps={{ size: `middle` }}
              cancelButtonProps={{ size: "middle" }}
              onConfirm={() =>
                !isLoading && !isGetLoading && list.length === 0
                  ? LoadNotification("[SYSTEM]", "삭제할 상품이 없습니다.", 0)
                  : cartDeleteAll()
              }
            >
              <Wrapper
                cursor="pointer"
                color={colorConfigs.basic.white}
                width={`calc(100% / 3 - 3px)`}
                height={`50px`}
                bgColor={colorConfigs.table_status.ban}
              >
                장바구니 비우기
              </Wrapper>
            </Popconfirm>

            <Wrapper
              cursor="pointer"
              color={colorConfigs.basic.white}
              width={`calc(100% / 3 - 3px)`}
              height={`50px`}
              bgColor={colorConfigs.table_status.existence}
              onClick={() =>
                isGetLoading && list.length === 0
                  ? LoadNotification("[SYSTEM]", "주문할 상품이 없습니다.", 0)
                  : selectedRowKeys.length === 0
                  ? LoadNotification("[SYSTEM]", "선택한 상품이 없습니다.", 0)
                  : !ordable
                  ? LoadNotification(
                      "[SYSTEM]",
                      "주문 불가한 상품이 있습니다.",
                      0
                    )
                  : goToPayment()
              }
            >
              구매하기
            </Wrapper>
          </Wrapper>
        </Wrapper>
      </Wrapper>
    </Wrapper>
  );
};

export default Cart;
// {
//   as_clnt: "10000",
//   as_cust: "311070",
//   as_jsondata: '[{"clnt":"10000","recid":1070,"ordqty":10,"ordprc":184200}]'
// }
